import { FunctionComponent } from 'react'

import rampLogo from '~/src/images/logos/logo-with-name.svg'
import { styled } from 'styled-components'

type Props = {
  onLogoClick?: (() => void) | null
}

const RigidPageHeaderContainer = styled.div`
  padding: 2.125rem 1.5rem;
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 2.125rem 5rem;
  }
`

export const RigidPageHeader: FunctionComponent<Props> = ({
  onLogoClick = () => window.location.replace('https://ramp.com'),
}) => {
  return (
    <RigidPageHeaderContainer onClick={onLogoClick === null ? undefined : onLogoClick}>
      <img src={rampLogo} alt='Ramp' height={25} />
    </RigidPageHeaderContainer>
  )
}
