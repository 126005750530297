import { FunctionComponent } from 'react'

import {
  RyuBreak,
  RyuGrid,
  RyuText,
  RyuButton,
  RyuPad,
  RyuFlex,
  useRyuBreakpoints,
  type RyuListViewItemSingleConfig,
} from '@ramp/ryu'

import { styled } from 'styled-components'
import { Testimonial } from '~/src/components/Testimonial'
import { ReviewTestimonial } from '../ReviewTestimonial'
import rampLogo from '~/src/images/logos/logo-with-name.svg'
import joffreyBalletHero from '~/src/images/testimonials/joffreyballet-hero.jpg'
import joffreyBalletLogo from '~/src/images/testimonials/joffreyballet-logo.png'
import { useForm } from 'react-hook-form'
import { KenForm } from '~/src/components/ken/KenForm/KenForm'
import { KenFieldText } from '~/src/components/ken/KenForm/KenFieldText'
import { KenFieldSelect } from '~/src/components/ken/KenForm/KenFieldSelect'
import { DropdownChoices, ContactSalesFormValues } from '../types'

const companySizeMap: DropdownChoices[] = [
  {
    label: '1-10 Employees',
    value: '1-10',
  },
  {
    label: '11-50 Employees',
    value: '11-50',
  },
  {
    label: '51-100 Employees',
    value: '51-100',
  },
  {
    label: '101-250 Employees',
    value: '101-250',
  },
  {
    label: '251+ Employees',
    value: '251+',
  },
]

const monthlySpendMap: DropdownChoices[] = [
  {
    label: 'Under $10,000/mo',
    value: '<10k',
  },
  {
    label: '$10,000 - $50,000/mo',
    value: '10k-50k',
  },
  {
    label: '$50,000 - $250,000/mo',
    value: '50k-250k',
  },
  {
    label: 'Over $250,000/mo',
    value: '250k+',
  },
]

const Flex = styled.div`
  display: flex;
  flex-direction: column;
`

const ContentMinWidthContainer = styled.div`
  min-width: 480px;
  @media (max-width: 767px) {
    min-width: 100%;
  }
`
export type Props = {
  companyLogoUrl?: string
  onSubmit: (data: ContactSalesFormValues) => void
}
export const ContactSalesForm: FunctionComponent<Props> = ({ onSubmit }) => {
  const initialValues = {
    first_name: '',
    last_name: '',
    company_name: '',
    email: '',
    company_size: null,
    monthly_spend: null,
  }
  const form = useForm<ContactSalesFormValues>({ defaultValues: initialValues })
  const isDesktop = useRyuBreakpoints({ minWidth: 'desktop' })

  return (
    <ContactSalesLayout>
      <RyuFlex gapSize='l' alignItems='flexStart' justifyContent='spaceBetween'>
        <ContentMinWidthContainer>
          <RyuPad sizeTop='xl' sizeBottom='xxl'>
            <img src={rampLogo} height={26} alt='Company Logo' />
            <div>
              <RyuText.h2 align='left' size='l' paddingVertical='top'>
                Learn what Ramp can do for you.
              </RyuText.h2>
              <RyuBreak size='s' />
              <RyuText.p align='left' size='m' color='hushed'>
                Schedule a 30-minute product demo with an expert specialist.
              </RyuText.p>
              <RyuBreak size='m' />
              <KenForm
                form={form}
                render={({ formProps }) => (
                  <form {...formProps}>
                    <Flex>
                      <KenFieldText<ContactSalesFormValues>
                        name='email'
                        type='email'
                        label='Work email'
                        variant='outlined'
                        hasError={!!form.formState.errors.email}
                        rules={{ required: true }}
                      />
                      <RyuBreak size='xs' />
                      <RyuGrid columns={2} responsiveColumns={1} gapSize='s'>
                        <KenFieldText<ContactSalesFormValues>
                          name='first_name'
                          label='First name'
                          variant='outlined'
                          hasError={!!form.formState.errors.first_name}
                          rules={{ required: true }}
                        />
                        <KenFieldText<ContactSalesFormValues>
                          name='last_name'
                          label='Last name'
                          variant='outlined'
                          hasError={!!form.formState.errors.last_name}
                          rules={{ required: true }}
                        />
                      </RyuGrid>

                      <RyuBreak size='xs' />
                      <KenFieldText<ContactSalesFormValues>
                        name='company_name'
                        label='Company name'
                        variant='outlined'
                        hasError={!!form.formState.errors.company_name}
                        rules={{ required: true }}
                      />

                      <RyuBreak size='xs' />

                      <KenFieldSelect<ContactSalesFormValues, 'company_size', DropdownChoices>
                        name='company_size'
                        label='Company size'
                        items={companySizeMap}
                        parseItem={parseCompanySizeItem}
                        variant='outlined'
                        hasError={!!form.formState.errors.company_size}
                        hideSearch={true}
                        rules={{ required: true }}
                      />

                      <RyuBreak size='xs' />

                      <KenFieldSelect<ContactSalesFormValues, 'monthly_spend', DropdownChoices>
                        name='monthly_spend'
                        label='Estimated monthly spend'
                        items={monthlySpendMap}
                        parseItem={parseMonthlySpendItem}
                        variant='outlined'
                        hasError={!!form.formState.errors.monthly_spend}
                        hideSearch={true}
                        rules={{ required: true }}
                      />

                      <RyuBreak size='l' />

                      <RyuButton
                        variant='prime'
                        _textAlign='center'
                        color='primary'
                        type='submit'
                        fullWidth={true}
                      >
                        Book a demo
                      </RyuButton>
                    </Flex>
                  </form>
                )}
                onSubmit={onSubmit}
              />
              <RyuBreak size='xl' />
              <ReviewTestimonial />
            </div>
          </RyuPad>
        </ContentMinWidthContainer>

        {isDesktop && (
          <RyuPad size='l'>
            <Testimonial
              companyLogoUrl={joffreyBalletLogo}
              narrow={true}
              companyName='Joffrey Ballet'
              description='Accounting systems can be so antiquated. There’s so much to automate, so much to improve and that’s something Ramp does so well. They’ve really modernized how teams can operate.'
              quoteAuthorName='Gee Hoon Lim'
              quoteAuthorTitle='Director of Finance'
              imageUrl={joffreyBalletHero}
            />
          </RyuPad>
        )}
      </RyuFlex>
    </ContactSalesLayout>
  )
}

function parseCompanySizeItem(company_size: DropdownChoices): RyuListViewItemSingleConfig {
  return {
    label: company_size.label,
    renderItem: () => company_size.label,
  }
}

function parseMonthlySpendItem(monthly_spend: DropdownChoices): RyuListViewItemSingleConfig {
  return {
    label: monthly_spend.label,
    renderItem: () => monthly_spend.label,
  }
}

const ContactSalesLayout = styled.div`
  max-width: 85em;
  margin: 0 auto;
  padding: 0 1.5rem;
`
