import { styled } from 'styled-components'

export const RigidPageContent = styled.div`
  width: 100%;
  max-width: 848px;
  margin-left: auto;
  margin-right: auto;
  padding: 24px;
  display: flex;
  flex: 1;
  flex-direction: column;
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin-top: 1.5rem;
    padding: 1rem 5rem;
  }
`
