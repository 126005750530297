/// <reference types="styled-components/cssprop" />
import { RyuTheme } from '@ramp/ryu'

import { colors as designSystemColors } from './design-system/colors'

const breakpoints: string[] & {
  phablet: string
  tablet: string
  desktop: string
  widescreen: string
  fullHd: string
} = ['576px', '768px', '992px', '1200px', '1920px'] as any
breakpoints.phablet = breakpoints[0]
breakpoints.tablet = breakpoints[1]
breakpoints.desktop = breakpoints[2]
breakpoints.widescreen = breakpoints[3]
breakpoints.fullHd = breakpoints[4]

const space = [0, 8, 16, 24, 32, 40, 48, 56, 64, 72, 80] as const

const fontSizes = {
  caption: 10,
  label: 12,
  body: 14,

  eyebrows: 10,
  legend: 12,
  links: 16,

  heading1: 104,
  heading2: 56,
  heading3: 40,
  heading4: 40,
  heading5: 28,

  mobileHeading1: 52,
  mobileHeading2: 36,
  mobileHeading3: 32,
  mobileHeading4: 28,
  mobileHeading5: 24,

  body1: 24,
  body2: 20,
  body3: 16,
  body4: 14,
} as const

const lineHeights = {
  label: '16px',
  body: 1.35,
  heading: 1.2,

  eyebrows: '14px',
  legend: '16px',
  links: '24px',

  heading1: '120px',
  heading2: '64px',
  heading3: '52px',
  heading4: '52px',
  heading5: '40px',

  mobileHeading1: '56px',
  mobileHeading2: '42px',
  mobileHeading3: '40px',
  mobileHeading4: '36px',
  mobileHeading5: '32px',

  body1: '36px',
  body2: '30px',
  body3: '24px',
  body4: '20px',
} as const

const fonts = {
  body: 'Lausanne, sans-serif',
  heading: 'inherit',
  landing: 'Lausanne, Helvetica Neue, Helvetica, serif',
  cursive: 'Pacifico, cursive',
} as const

const fontWeights = {
  light: 300,
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
} as const

const radii = {
  none: 0,
  standard: 4,
  heavy: 16,
  circle: '50%',
} as const

const letterSpacings = {
  label: '0.04em',
} as const

export const colors = {
  ...designSystemColors,

  text: designSystemColors.darkChampagne3,
  primaryText: designSystemColors.darkChampagne3,
  secondaryText: designSystemColors.darkChampagne2,
  tertiaryText: designSystemColors.darkChampagne1,

  tableText: designSystemColors.darkChampagne3,
  secondaryTableText: designSystemColors.darkChampagne2,

  tableHeader: designSystemColors.darkChampagne2,
  tableRowHover: designSystemColors.lightBeige,

  menuItemHover: designSystemColors.lightBeige,

  splitTransaction: designSystemColors.yellow,

  outline: designSystemColors.lightChampagne,
  outlineTable: designSystemColors.beige,
  outlineTableRowHover: designSystemColors.darkBeige,

  outlineInputHover: designSystemColors.darkChampagne2,
  outlineInputActive: designSystemColors.darkChampagne3,
  outlineInputError: designSystemColors.primaryRed,
  outlineInputSuccess: designSystemColors.lightGreen3,
  outlineInputDisabled: designSystemColors.lightGray3,
  outlineInputDefault: designSystemColors.darkChampagne1,

  background: designSystemColors.white,
  disabledBackground: designSystemColors.lightGray1,

  error: designSystemColors.primaryRed,
  danger: designSystemColors.primaryRed,
  warning: designSystemColors.lightGold2,
  success: designSystemColors.primaryGreen,
  pending: designSystemColors.mediumGray2,
  disabled: designSystemColors.mediumGray2,
  dismissed: designSystemColors.mediumGray2,
  noData: designSystemColors.darkChampagne1,

  activeMapping: designSystemColors.lightGold2,
  inactiveMapping: designSystemColors.champagne,
  cardholderEdited: designSystemColors.lightOrange3,

  overlayBackground: 'rgba(244, 243, 239, 0.8)', // also named "Fog" in design system

  gray1: '#F7F7F8',
  gray2: '#DBDBDD',
  gray3: '#87898C',
  textGreen: '#33cca1',
} as const

const borders = {
  outline: `1px solid ${colors.outline}`,
  outlineTable: `1px solid ${colors.outlineTable}`,
  outlineTableRowHover: `1px solid ${colors.outlineTableRowHover}`,

  outlineGray3: `1px solid ${colors.gray3}`,
  outlineWarning: `1px solid ${colors.warning}`,
  outlineDashed: `1px dashed ${colors.outline}`,
  outlineText: `1px solid ${colors.text}`,
  outlineError: `1px solid ${colors.error}`,
  outlineTransparent: '1px solid transparent',

  outlineInputHover: `1px solid ${colors.outlineInputHover}`,
  outlineInputActive: `1px solid ${colors.outlineInputActive}`,
  outlineInputError: `1px solid ${colors.outlineInputError}`,
  outlineInputSuccess: `1px solid ${colors.outlineInputSuccess}`,
  outlineInputDisabled: `1px solid ${colors.outlineInputDisabled}`,
  outlineInputDefault: `1px solid ${colors.outlineInputDefault}`,

  outlineMenu: `1px solid ${colors.champagne}`,
} as const

const shadows = {
  popover: '0 0 1px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.125)',
  cardMildHover: '0px 8px 16px rgba(161, 163, 166, 0.08), 0px 4px 24px rgba(161, 163, 166, 0.08)',
  dropshadowHeight: '0px 5px 16px rgba(212, 212, 212, 0.6)',
} as const

export const theme = {
  borders,
  breakpoints,
  colors,
  fonts,
  fontSizes,
  fontWeights,
  letterSpacings,
  lineHeights,
  name: 'Ramp Light',
  radii,
  shadows,
  space,
} as const

type Theme = typeof theme

declare module 'styled-components' {
  export interface DefaultTheme extends RyuTheme, Theme {}
}
