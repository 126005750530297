import { Fragment, FunctionComponent, useCallback, useState } from 'react'
import CookiesUtil from '~/src/utils/cookies'
import { useUpdateLeadModel } from '~/src/models/leads'
import { trackEvent } from '~/src/utils/analytics'
import { isProduction } from '~/src/utils/env'
import { logError } from '~/src/utils/errors'
import { ContactSalesForm } from './ContactSalesContent'
import { RyuLoadingOverlay } from '@ramp/ryu'
import { BankBalance, useCreateLead } from '~/src/hooks/leads'
import { ContactSalesFormValues } from './types'
import { redirectToDemoFallback } from '../BookADemo/redirectUtils'

interface ContactSalesWrapperProps {
  onSubmit?: (data: ContactSalesFormValues) => void
}

const ContactSalesWrapper: FunctionComponent<ContactSalesWrapperProps> = (props) => {
  const { onSubmit } = props

  const [isUpdating, setIsUpdating] = useState(false)
  const [has25k, setHas25k] = useState<boolean | null>(null)
  const createLead = useCreateLead()
  const updateLead = useUpdateLeadModel()

  const generateLead = useCallback(
    async (data: ContactSalesFormValues) => {
      const leadResponse = await createLead(data.email, {
        discovery_method: 'contact_sales',
        first_name: data.first_name,
        last_name: data.last_name,
        company_name: data.company_name,
        employee_count: data.company_size?.value,
        estimated_monthly_spend: data.monthly_spend?.value,
        bank_balance: data.has_over_25k ? BankBalance.OVER_25K : BankBalance.UNDER_25K,
      })
      if (!leadResponse) {
        throw new Error('Lead creation failed')
      }

      const leadId = leadResponse.id

      return leadId
    },
    [createLead]
  )

  const onFormSubmit = useCallback(
    async (data: ContactSalesFormValues) => {
      try {
        if (data.has_over_25k === false) {
          setHas25k(false)
          return
        }

        setIsUpdating(true)
        setHas25k(true)
        const leadId = await generateLead(data)

        const cookies = CookiesUtil.getAll()
        const eventPayload = {
          fbp: cookies._fbp,
          fbc: cookies._fbc,
          first_name: data.first_name,
          last_name: data.last_name,
          company_name: data.company_name,
          company_email: data.email,
          employee_count: data.company_size?.value,
          monthly_spend: data.monthly_spend?.value ?? null,
          bank_balance: data.has_over_25k ? BankBalance.OVER_25K : BankBalance.UNDER_25K,
          discovery_method: 'contact_sales',
        }

        if (isProduction) {
          if (window.ChiliPiper) {
            trackEvent('Chilipiper Widget - Calendar Requested', eventPayload)

            window.ChiliPiper.submit('ramp-com', 'sdr-queue', {
              lead: {
                FirstName: data.first_name,
                LastName: data.last_name,
                Email: data.email,
              },
              onRouted: () => {
                trackEvent('Chilipiper Widget - Calendar Shown', eventPayload)
              },
              onSuccess: async () => {
                await updateLead(leadId, {
                  requested_demo: true,
                  sales_demo_booked_at: new Date().toISOString(),
                })
                trackEvent('Chilipiper Widget - Meeting Successfully Booked', eventPayload)
                if (onSubmit) {
                  onSubmit(data)
                }
              },
              onClose: () => {
                trackEvent('Chilipiper Widget - User Exited Without Booking Meeting', eventPayload)
              },
            })
          } else {
            trackEvent("Unable to Render Chilipiper Widget due to User's Ad Blocker", eventPayload)
          }
        }
      } catch (e: any) {
        logError.high(e, {
          tags: { owner: 'rshen_ramp' },
        })
        redirectToDemoFallback(data.email)
      } finally {
        setIsUpdating(false)
      }
    },
    [generateLead, updateLead, onSubmit]
  )

  return (
    <Fragment>
      {isUpdating && <RyuLoadingOverlay />}
      <ContactSalesForm has25k={has25k} onSubmit={onFormSubmit} />
    </Fragment>
  )
}

export default ContactSalesWrapper
