export const colors = {
  white: '#FFFFFF',
  lightGray1: '#FBFBFB',
  lightGray2: '#F1F2F2',
  lightGray3: '#D4D4D4',
  mediumGray2: '#969696',
  black: '#000000',

  lightBeige: '#FCFBFA',
  beige: '#F4F3EF',
  darkBeige: '#E8E8DC',
  lightChampagne: '#DEDECC',
  champagne: '#C6C6A7',
  darkChampagne1: '#918F7A',
  darkChampagne2: '#787868',
  darkChampagne3: '#2E2E27',

  yellow: '#F5FF78',
  lightGold1: '#E3DE6E',
  lightGold2: '#D1BD61',
  lightGold3: '#BD9C57',

  lightGreen3: '#2EC45C',
  primaryGreen: '#00AC42',
  darkGreen: '#008F37',

  lightOrange2: '#FF9E69',
  lightOrange3: '#FF7A36',
  primaryRed: '#FF5500',
  darkOrange: '#E64100',
} as const
