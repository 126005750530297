import { type FirebaseApp, initializeApp } from 'firebase/app'
import {
  type Auth,
  type User,
  createUserWithEmailAndPassword as _createUserWithEmailAndPassword,
  connectAuthEmulator,
  getAuth as getFirebaseAuth,
} from 'firebase/auth'

export type FirebaseUser = User

export enum IdentityProvider {
  PASSWORD = 'password',
  GOOGLE = 'google.com',
  OKTA = 'OKTA',
  RIPPLING = 'RIPPLING',
  AZURE_AD = 'AZURE_AD',
  ADP = 'ADP',
  ONELOGIN = 'ONELOGIN',
  JUMPCLOUD = 'JUMPCLOUD',
}

export type SAMLProvider =
  | IdentityProvider.OKTA
  | IdentityProvider.RIPPLING
  | IdentityProvider.AZURE_AD
  | IdentityProvider.ADP
  | IdentityProvider.ONELOGIN
  | IdentityProvider.JUMPCLOUD

export const IDENTITY_PROVIDER_TO_DISPLAY_NAME: Record<IdentityProvider, string> = {
  [IdentityProvider.PASSWORD]: 'Password',
  [IdentityProvider.GOOGLE]: 'Google',
  [IdentityProvider.OKTA]: 'Okta',
  [IdentityProvider.RIPPLING]: 'Rippling',
  [IdentityProvider.AZURE_AD]: 'Microsoft Azure AD',
  [IdentityProvider.ADP]: 'ADP',
  [IdentityProvider.ONELOGIN]: 'OneLogin',
  [IdentityProvider.JUMPCLOUD]: 'JumpCloud',
}

let app: FirebaseApp | null = null

export const EMULATOR_URL = getAuthEmulatorUrl()

export function init() {
  app = initializeApp({
    apiKey: import.meta.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: import.meta.env.REACT_APP_FIREBASE_DOMAIN,
    databaseURL: import.meta.env.REACT_APP_FIREBASE_DB_URL,
    projectId: import.meta.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: import.meta.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.REACT_APP_FIREBASE_SENDER_ID,
    appId: import.meta.env.REACT_APP_FIREBASE_APP_ID,
  })

  const auth = getAuth()

  if (EMULATOR_URL) {
    connectAuthEmulator(auth, EMULATOR_URL, { disableWarnings: true })
  }
}

function getAuth() {
  if (!app) {
    throw new Error('Firebase not initialized, call `init()` first')
  }

  return getFirebaseAuth(app)
}

export const createUserWithEmailAndPassword = withAuth(_createUserWithEmailAndPassword)

type RestParameters<TFn> = TFn extends (auth: Auth, ...args: infer TRest) => any ? TRest : never

function withAuth<TFn extends (auth: Auth, ...args: any[]) => any>(fn: TFn) {
  return (...args: RestParameters<TFn>): ReturnType<TFn> => fn(getAuth(), ...args)
}

function getAuthEmulatorUrl() {
  if (import.meta.env.REACT_APP_AUTH_EMULATOR_URL) {
    return import.meta.env.REACT_APP_AUTH_EMULATOR_URL
  }
}
