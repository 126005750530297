import { type ReactNode } from 'react'

import { RyuFlex, type RyuFlexGapSize } from '@ramp/ryu'

type Props = {
  children?: ReactNode

  /**
   *
   * Controls the gap between items.
   */
  gapSize: RyuFlexGapSize
}

/**
 *
 * One column layout. If `gapSize` is not provided, consider using a `<div>`.
 */
export function KenStack({ children, ...props }: Props) {
  return (
    <RyuFlex direction='column' alignItems='stretch' {...props}>
      {children}
    </RyuFlex>
  )
}
