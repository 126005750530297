import { styled } from 'styled-components'

import { type ReactNode } from 'react'

import { useRyuBreakpoints } from '@ramp/ryu'

import { RIGID_PAGE_ASIDE_MAX_WIDTH, RIGID_PAGE_MAX_WIDTH } from '~/src/components/RigidPage/constants'

type Props = {
  rightContent?: ReactNode
  center?: boolean
  children?: ReactNode
}

export function RigidForm({ rightContent, children, center }: Props) {
  const isDesktop = useRyuBreakpoints({ minWidth: 'desktop' })

  return (
    <RigidFormRoot className={center ? 'RigidFormRoot--center' : ''}>
      {children}

      {isDesktop && !!rightContent && (
        <RigidFormWrapper>
          <RigidFormAside>{rightContent}</RigidFormAside>
        </RigidFormWrapper>
      )}
    </RigidFormRoot>
  )
}

const RigidFormWrapper = styled.div`
  display: flex;
  justify-content: center;
  @media only screen and (min-width: ${({ theme }) => theme.ryuBreakpoints.tablet}px) {
    width: ${RIGID_PAGE_ASIDE_MAX_WIDTH};
  }
`

const RigidFormRoot = styled.div`
  max-width: ${RIGID_PAGE_MAX_WIDTH};
  width: 100%;
  height: 100%;
  display: flex;
  align-self: center;
  flex-grow: 1;
  justify-content: flex-start;

  &.RigidFormRoot--center {
    justify-content: space-around;
  }
`

const RigidFormAside = styled.div`
  display: flex;
  flex-direction: column;
  max-width: fit-content;
  height: fit-content;
  flex: 1;
  margin-top: 0;

  @media only screen and (min-width: ${({ theme }) => theme.ryuBreakpoints.tablet}px) {
    margin-top: ${({ theme }) => theme.ryuSpacingSizes.xs}rem;
  }
`
