import { type ForwardedRef, forwardRef } from 'react'
import {
  type ControllerRenderProps,
  type FieldPath,
  type FieldPathValue,
  type FieldValues,
  type UseControllerProps,
  useController,
} from 'react-hook-form'

import { RyuInputSelect, type RyuInputSelectProps, useCombinedRef } from '@ramp/ryu'

import { type KenFieldGetCaption, getKenFieldCaption } from './utils'

type RequiredAllowNullSelection<
  TFieldValues extends FieldValues = never,
  TName extends FieldPath<TFieldValues> = never,
> =
  null extends FieldPathValue<TFieldValues, TName>
    ? {
        allowNullSelection?: boolean
      }
    : {
        allowNullSelection: false
      }

export type KenFieldSelectProps<
  TFieldValues extends FieldValues = never,
  TName extends FieldPath<TFieldValues> = never,
  TItem extends FieldPathValue<TFieldValues, TName> = FieldPathValue<TFieldValues, TName>,
> = {
  emptyFieldLabel?: string
  getCaption?: KenFieldGetCaption
  onBeforeChange?: (nextValue: TItem | null, event: { preventDefault: () => void }) => void
  onChange?: (nextValue: TItem | null) => void
} & UseControllerProps<TFieldValues, TName> &
  Omit<
    RyuInputSelectProps<TItem>,
    | Exclude<keyof ControllerRenderProps<TFieldValues, TName>, 'onBlur'>
    | 'setPills'
    | 'getPillKey'
    | 'renderPill'
  > &
  RequiredAllowNullSelection<TFieldValues, TName>

/**
 *
 * `react-hook-form` version of `<RyuInputSelect>`.
 *
 * @typeParam TFieldValues - The type for the form field values.
 *
 * @typeParam TName - The name of the field in `TFieldValues`. Required for type-safety.
 *
 * @typeParam TItem - The type of `items`. Required if the field value is nullable (`TItem | null`).
 *
 * @example
 * <KenFieldSelect<AddressFieldValues, 'state'> name='state' label='State' />
 *                                  // ^^^^^^^
 *
 * @example
 * // The `TItem` generic must be specified if the field value is nullable (`TItem | null`).
 * <KenFieldSelect<AddressFieldValues, 'state', string> name='state' label='State' />
 *                                           // ^^^^^^
 */
function RefForwardedKenFieldSelect<
  TFieldValues extends FieldValues = never,
  TName extends FieldPath<TFieldValues> = never,
  TItem extends FieldPathValue<TFieldValues, TName> = FieldPathValue<TFieldValues, TName>,
>(
  {
    label,
    emptyFieldLabel,
    caption,
    getCaption,
    required,
    onBeforeChange,
    onChange,
    onBlur,
    allowNullSelection = true,
    ...restProps
  }: KenFieldSelectProps<TFieldValues, TName, TItem>,
  forwardedRef: ForwardedRef<HTMLInputElement>
) {
  const {
    field,
    fieldState: { error },
  } = useController<TFieldValues, TName>({ ...restProps, rules: { required, ...restProps.rules } })

  const combinedRef = useCombinedRef<HTMLInputElement>(field.ref, forwardedRef)

  const isNullishValue = field.value === null

  return (
    <RyuInputSelect<TItem>
      {...restProps}
      {...field}
      ref={combinedRef}
      allowNullSelection={allowNullSelection}
      label={isNullishValue ? emptyFieldLabel ?? label : label}
      caption={getKenFieldCaption({ error, caption, getCaption })}
      required={required}
      hasError={!!error}
      onChange={handleChange}
      onBlur={(event) => {
        onBlur?.(event)
        field.onBlur()
      }}
    />
  )

  function handleChange(nextValue: TItem | null) {
    if (!allowNullSelection && nextValue === null) {
      return
    }

    let isDefaultPrevented = false

    onBeforeChange?.(nextValue, {
      preventDefault: () => {
        isDefaultPrevented = true
      },
    })

    if (isDefaultPrevented) {
      return
    }

    field.onChange(nextValue)
    onChange?.(nextValue)
  }
}

export const KenFieldSelect = forwardRef(RefForwardedKenFieldSelect) as <
  TFieldValues extends FieldValues = never,
  TName extends FieldPath<TFieldValues> = never,
  TItem extends FieldPathValue<TFieldValues, TName> = FieldPathValue<TFieldValues, TName>,
>(
  props: KenFieldSelectProps<TFieldValues, TName, TItem> & { ref?: ForwardedRef<HTMLInputElement> }
) => ReturnType<typeof RefForwardedKenFieldSelect>
