import { Fragment, FunctionComponent } from 'react'

import { RyuBreak, RyuProgressBar, RyuText } from '@ramp/ryu'

import { RigidPageContent } from '~/src/components/RigidPageContent'
import { RadioPicker } from '../RadioPicker'
import { StepHeader } from '../StepHeader'

type Props = {
  onChange: (value: string) => void
}

export const CompanySize: FunctionComponent<Props> = ({ onChange }) => {
  return (
    <Fragment>
      <RyuProgressBar value={25} size='l' color='accent' />

      <RigidPageContent>
        <RyuText align='center' color='hushed' size='l'>
          Is Ramp right for your business?
        </RyuText>
        <StepHeader>How big is your company?</StepHeader>
        <RyuBreak size='l' />
        <RadioPicker
          options={[
            {
              label: '1-10 Employees',
              value: '1-10',
            },
            {
              label: '11-50 Employees',
              value: '11-50',
            },
            {
              label: '51-100 Employees',
              value: '51-100',
            },
            {
              label: '101-250 Employees',
              value: '101-250',
            },
            {
              label: '251+ Employees',
              value: '251+',
            },
          ]}
          onChange={(value) => {
            onChange(value)
          }}
        />
      </RigidPageContent>
    </Fragment>
  )
}
