export const parseCookie = (str: string) =>
  str
    .split(';')
    .map((v) => v.split('='))
    .reduce((acc: any, v) => {
      acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim())
      return acc
    }, {})

export const pageProperties = () => {
  const cookies: any = parseCookie(document.cookie)
  return {
    ramp_attid: cookies.initial_ramp_attid ?? cookies.ramp_attid,
    gclid: cookies.initial_gclid ?? cookies.gclid,
    gclid_ts: cookies.initial_gclid_ts ?? cookies.gclid_ts,
    fbp: cookies._fbp,
    fbc: cookies._fbc,
    fbc_ts: cookies._fbc_ts,
    msclkid: cookies.initial_msclkid,
    msclkid_ts: cookies.initial_msclkid_ts,
    ttclid: cookies.initial_ttclid,
    ttclid_ts: cookies.initial_ttclid_ts,
    impact_crm_clickid: cookies.initial_irclickid,
    fintel_clickid: cookies.initial_ftclick,
    fintel_clickid_ts: cookies.initial_ftclick_ts,
  }
}

export function installPageEnrichmentForAdTracking(): Promise<any> {
  return window.analytics.register({
    type: 'before',
    isLoaded: () => true,
    load: () => Promise.resolve(),
    page: (ctx: any) => {
      ctx.event.properties = {
        ...pageProperties(),
        ...ctx.event.properties,
      }
      return ctx
    },
  })
}
