import { useState } from 'react'

import { useKeyboardShortcut } from '@ramp/ryu'

import { type WrapAPIRequestParams, useWrappedAPIRequest } from '~/src/utils/useWrapAPIRequest'

type DefaultRequestFn = ((...parameters: any[]) => void) | undefined

export type UseKenApiRequestParams<TOnRequestFunction extends DefaultRequestFn> = {
  submitOnCommandEnter?: boolean
  requestProps?: Omit<WrapAPIRequestParams, 'callbackFunction'>
  onSuccess?: TOnRequestFunction
}

export function useKenApiRequest<TOnRequestFunction extends DefaultRequestFn>(
  onRequest: TOnRequestFunction,
  {
    requestProps = {},
    submitOnCommandEnter = false,
    onSuccess,
  }: UseKenApiRequestParams<TOnRequestFunction> = {}
) {
  const [isLoading, setIsLoading] = useState(false)

  const { setIsLoading: consumerSetIsLoading, ...passthroughRequestProps } = requestProps

  const makeRequest = useWrappedAPIRequest<void, []>({
    ...passthroughRequestProps,
    callbackFunction,
    setIsLoading: localSetIsLoading,
  })

  useKeyboardShortcut(['command', 'enter'], makeRequest, {
    disabled: submitOnCommandEnter === false,
  })

  return {
    isLoading,
    makeRequest,
  }

  async function callbackFunction(...parameters: Parameters<NonNullable<TOnRequestFunction>>) {
    await onRequest?.(...parameters)
    await onSuccess?.(...parameters)
  }

  function localSetIsLoading(nextIsLoading: boolean) {
    setIsLoading(nextIsLoading)
    consumerSetIsLoading?.(nextIsLoading)
  }
}
