import { Fragment, FunctionComponent, useCallback, useState } from 'react'
import CookiesUtil from '~/src/utils/cookies'
import { useUpdateLeadModel } from '~/src/models/leads'
import { trackEvent } from '~/src/utils/analytics'
import { isProduction } from '~/src/utils/env'
import { logError } from '~/src/utils/errors'
import { ContactSalesForm } from './VerticalizedContent'
import { RyuLoadingOverlay } from '@ramp/ryu'
import { BankBalance, useCreateLead } from '~/src/hooks/leads'
import { ContactSalesFormValues } from '../types'
import { redirectToDemoFallback } from '../../BookADemo/redirectUtils'

interface ContactSalesWrapperProps {
  onSubmit?: (data: ContactSalesFormValues) => void
}

function isOver100FTE(employee_count: string | undefined): boolean {
  if (!employee_count) {
    return false
  }

  switch (employee_count) {
    case '1-10':
      return false
    case '11-50':
      return false
    case '51-100':
      return false
    case '101-250':
      return true
    case '251+':
      return true
    default:
      return false
  }
}

const ContactSalesWrapper: FunctionComponent<ContactSalesWrapperProps> = (props) => {
  const { onSubmit } = props

  const overrideProdChilipiper =
    new URLSearchParams(window.location.search).get('override_prod_chilipiper') === 'true'

  const [isUpdating, setIsUpdating] = useState(false)
  const createLead = useCreateLead()
  const updateLead = useUpdateLeadModel()

  const generateLead = useCallback(
    async (data: ContactSalesFormValues) => {
      const leadResponse = await createLead(data.email, {
        discovery_method: 'contact_sales',
        first_name: data.first_name,
        last_name: data.last_name,
        company_name: data.company_name,
        employee_count: data.company_size?.value,
        estimated_monthly_spend: data.monthly_spend?.value,
        bank_balance: data.has_over_25k ? BankBalance.OVER_25K : BankBalance.UNDER_25K,
      })
      if (!leadResponse) {
        throw new Error('Lead creation failed')
      }

      const leadId = leadResponse.id

      return leadId
    },
    [createLead]
  )

  const onFormSubmit = useCallback(
    async (data: ContactSalesFormValues) => {
      try {
        setIsUpdating(true)
        const leadId = await generateLead(data)

        const chiliPiperQueue = isOver100FTE(data.company_size?.value)
          ? 'verticalized-over-100-fte'
          : 'verticalized-under-100-fte'

        const cookies = CookiesUtil.getAll()
        const eventPayload = {
          fbp: cookies._fbp,
          fbc: cookies._fbc,
          first_name: data.first_name,
          last_name: data.last_name,
          company_name: data.company_name,
          company_email: data.email,
          employee_count: data.company_size?.value,
          monthly_spend: data.monthly_spend?.value ?? null,
          bank_balance: data.has_over_25k ? BankBalance.OVER_25K : BankBalance.UNDER_25K,
          discovery_method: 'contact_sales',
        }

        if (isProduction || overrideProdChilipiper) {
          if (window.ChiliPiper) {
            trackEvent('Chilipiper Widget - Calendar Requested', eventPayload)

            window.ChiliPiper.submit('ramp-com', chiliPiperQueue, {
              lead: {
                FirstName: data.first_name,
                LastName: data.last_name,
                Email: data.email,
              },
              onRouted: () => {
                trackEvent('Chilipiper Widget - Calendar Shown', eventPayload)
              },
              onSuccess: async () => {
                await updateLead(leadId, {
                  requested_demo: true,
                  sales_demo_booked_at: new Date().toISOString(),
                })
                trackEvent('Chilipiper Widget - Meeting Successfully Booked', eventPayload)
                if (onSubmit) {
                  onSubmit(data)
                }
              },
              onClose: () => {
                trackEvent('Chilipiper Widget - User Exited Without Booking Meeting', eventPayload)
              },
            })
          } else {
            trackEvent("Unable to Render Chilipiper Widget due to User's Ad Blocker", eventPayload)
          }
        }
      } catch (e: any) {
        logError.high(e, {
          tags: { owner: 'rshen_ramp' },
        })
        redirectToDemoFallback(data.email)
      } finally {
        setIsUpdating(false)
      }
    },
    [generateLead, overrideProdChilipiper, updateLead, onSubmit]
  )

  return (
    <Fragment>
      {isUpdating && <RyuLoadingOverlay />}
      <ContactSalesForm onSubmit={onFormSubmit} />
    </Fragment>
  )
}

export default ContactSalesWrapper
