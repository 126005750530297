import { styled } from 'styled-components'

import { ReactComponent } from './rampLogo.svg'

type Props = {
  size?: 'm'
}

type KenRampLogoRootProps = {
  $width: number
}

const sizes = {
  m: 5.625,
}

const KenRampLogoRoot = styled.div<KenRampLogoRootProps>`
  width: ${({ $width }) => $width}rem;

  > svg {
    display: block;
  }
`

export function KenRampLogo({ size = 'm' }: Props) {
  return (
    <KenRampLogoRoot $width={sizes[size]}>
      <ReactComponent />
    </KenRampLogoRoot>
  )
}
