import { RyuButton, RyuText, RyuFlex } from '@ramp/ryu'

type Props = {
  email: string
}

export function UserAlreadyExists({ email }: Props) {
  return (
    <RyuFlex direction='column'>
      <RyuText.div color='hushed'>
        Looks like you already have an account. Please sign in to get started.
      </RyuText.div>

      <RyuText.h4>{email}</RyuText.h4>

      <RyuButton
        color='constructive'
        variant='prime'
        href={`/sign-in?email=${encodeURIComponent(email)}`}
      >
        Sign in to Ramp
      </RyuButton>
    </RyuFlex>
  )
}
