import { RyuText, RyuButton } from '@ramp/ryu'
import { styled } from 'styled-components'
import { trackEvent } from '~/src/utils/analytics'
const FixedContainer = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
`

const Banner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 1;
  background-color: ${({ theme }) => theme.ryuColors.lightAccent};
  text-align: center;
  padding: 0.25rem;
  gap: 0.5rem;
`

export const AccountingAutomationDemo = () => {
  return (
    <FixedContainer>
      <Banner>
        <RyuText size='s'>Enjoyed the demo?</RyuText>
        <RyuButton
          _textAlign='center'
          variant='prime'
          size='xs'
          onClick={() => {
            // Let user fill out full pre-qual flow
            window.open('https://ramp.com/qualification', '_blank')
            trackEvent('Get Ramp Clicked - Self Service Demo', {
              location: 'demo page top banner',
            })
          }}
        >
          Get Ramp – for Free
        </RyuButton>
      </Banner>
      <iframe
        src='https://ramp.navattic.com/fg1c05u8'
        title='Ramp Interactive Accounting Automation Demo'
        style={{
          width: '100%',
          height: '100%',
          border: 'none',
          margin: 0,
          padding: 0,
          overflow: 'hidden',
        }}
      />
    </FixedContainer>
  )
}
