import { RyuFlex, RyuFlexGrow, RyuBreak, RyuText } from '@ramp/ryu'

export function HairlineDividerWithText({ text }: { text: string }) {
  return (
    <RyuFlex gapSize='s'>
      <RyuFlexGrow>
        <RyuBreak as='hr' />
      </RyuFlexGrow>

      <RyuText.span color='hushed' size='s'>
        {text}
      </RyuText.span>

      <RyuFlexGrow>
        <RyuBreak as='hr' />
      </RyuFlexGrow>
    </RyuFlex>
  )
}
