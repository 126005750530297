import { isDevelopment } from '../env'
import {
  UserQualificationEvent,
  LoggingEvent,
  ExploreProductEvent,
  DemoRequestedEvent,
  UserEvent,
  QualifiedEvent,
} from './types'

export function trackEvent(
  ...args:
    | UserQualificationEvent
    | LoggingEvent
    | ExploreProductEvent
    | DemoRequestedEvent
    | UserEvent
    | QualifiedEvent
) {
  const [eventName, properties] = args

  /**
   * These `console.log`s are used to debug Segment events.
   */
  if (isDevelopment && import.meta.env.REACT_APP_LOG_SEGMENT_EVENTS === 'true') {
    /* eslint-disable no-console */
    console.groupCollapsed(`%cTracked Segment Event - %c${eventName}`, 'color: green;', 'color: white;')
    console.log('Properties', properties)
    console.groupEnd()
    /* eslint-enable no-console */
  }

  window.analytics.track(eventName, properties)
}
