import { unauthedApi } from '~/src/api'

type ExistingIdentityResponse = {
  existing_identity: boolean
}

export async function checkIdentityExists(email: string): Promise<boolean> {
  const { existing_identity } = await unauthedApi
    .get('users/existing-identity', {
      searchParams: {
        email_address: email,
      },
    })
    .json<ExistingIdentityResponse>()

  return existing_identity
}
