import { HTTPError } from 'ky'
export type { LogParams } from './logError'
export { logError, URGENCY } from './logError'
export { parseHttpResponseError } from './parseHttpResponseError'

export function isHttpError(error: unknown, code?: number): error is HTTPError {
  if (!(error instanceof HTTPError)) {
    return false
  }

  if (code !== undefined) {
    return error.response.status === code
  }

  return true
}
