import { useCallback, useEffect } from 'react'
import { Redirect, useHistory, useLocation } from 'react-router-dom'

import { RyuBreak, RyuLink, RyuText, useRyuToast } from '@ramp/ryu'

import { RigidLayout } from '~/src/components/RigidLayout'
import { useKenApiRequest } from '~/src/hooks/useKenApiRequest'
import { unauthedApi } from '~/src/api'
import { trackEvent } from '~/src/utils/analytics'
import { pageProperties } from '~/src/utils/analytics/pageEnrichment'

type LocationState = {
  email?: string
  sendOnLoad?: boolean
}

export function VerifyUserEmailPage() {
  const history = useHistory()
  const { pathname, state } = useLocation<LocationState>()
  const showToast = useRyuToast()
  useEffect(() => {
    trackEvent('Verify Email Page Viewed', { email: state?.email ?? '', ...pageProperties() })
    trackEvent('SS CS DR VE Total Page Viewed', { email: state?.email ?? '', ...pageProperties() })
  }, [state?.email])
  const { makeRequest: resend } = useKenApiRequest(async () => {
    if (!state?.email) {
      throw new Error('Email is missing')
    }

    await sendVerificationEmail(state.email)
  })
  useEffect(() => {
    if (!state?.sendOnLoad) {
      return
    }

    resend()
    history.replace(pathname, { ...state, sendOnLoad: false })
  }, [history, pathname, resend, state])
  const onResendClick = useCallback(async () => {
    await resend()

    showToast({
      title: 'Verification email resent.',
      color: 'constructive',
      iconType: 'mail',
    })
  }, [resend, showToast])
  if (!state?.email) {
    return <Redirect to='/sign-in' />
  }

  return (
    <RigidLayout pageTitle='Verify Your Email' align='center'>
      <RyuText.h2 align='center'>Verify Your Email</RyuText.h2>

      <RyuText.div size='xl' color='hushed' align='center'>
        Click the link in the confirmation email sent to{' '}
        <RyuText.span color='primary' size='xl'>
          {state.email}
        </RyuText.span>{' '}
        to verify your account and continue with the application.
      </RyuText.div>

      <RyuBreak size='xl' />

      <RyuText.div color='hushed' align='center'>
        <RyuLink
          href='https://gmail.com#search/verify+your+Ramp+account'
          onClick={() => {
            trackEvent('Verify Email Gmail Filter Clicked')
          }}
        >
          Search for verification email in Gmail
        </RyuLink>
        <RyuBreak size='xs' />
        It may take a few minutes for the email to arrive (check your spam folder).
      </RyuText.div>

      <RyuText.div color='hushed' align='center'>
        Didn’t get it? <RyuLink onClick={onResendClick}>Resend Email</RyuLink>.
      </RyuText.div>
    </RigidLayout>
  )
}

async function sendVerificationEmail(email: string) {
  await unauthedApi.post('send-verification-email', {
    json: { email, use_email_otp: true },
  })
}
