import { unauthedApi } from '~/src/api'

type ExistingBusinessResponse = {
  result: string | null
  has_invite?: boolean
}

/**
 * We have implemented a workaround where users can click twice the submit button
 * to bypass this error, as indicated in the error message. So if the error has shown
 * and user clicks again, we don't validate anymore.
 *
 * Given this logic, we return `valid` to validate the error and `nextError` to store
 * the error in state.
 */
export async function hasExistingBusiness({
  email,
  existingBusinessError,
}: {
  email: string
  existingBusinessError: string | null
}): Promise<{ valid: boolean; nextError: string | null }> {
  const { result, has_invite } = await unauthedApi
    .get('users/existing_business', {
      searchParams: {
        email_address: email,
      },
    })
    .json<ExistingBusinessResponse>()

  if (!result) {
    // Clear any existing error set in the past
    return { valid: true, nextError: null }
  }

  if (has_invite) {
    return {
      valid: false,
      nextError: `Please check your email for an invite to join ${result} on Ramp.`,
    }
  }

  const nextExistingBusinessError =
    'Is your email associated with an existing Ramp business? If so, reach out to your admin for an invite. Otherwise you can continue signing up to start a new application.'

  if (existingBusinessError === nextExistingBusinessError) {
    return {
      // If the same error was shown once and user clicks again, we don't validate anymore.
      valid: true,
      nextError: nextExistingBusinessError,
    }
  }

  return { valid: false, nextError: nextExistingBusinessError }
}
