import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

export function useRouteHistory(): void {
  const history = useHistory()

  useEffect(() => {
    return history.listen(() => {
      window.analytics.page()
    })
  }, [history])
}
