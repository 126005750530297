import { styled } from 'styled-components'

import { Fragment, FunctionComponent } from 'react'

import { useScript } from '~/src/hooks/useScript'
import { RyuBreak } from '@ramp/ryu'

const Container = styled.div`
  padding: 56.25% 0 0 0;
  position: relative;
`

export const WistiaDemoVideo: FunctionComponent = () => {
  useScript({ src: 'https://fast.wistia.com/embed/medias/hv40ovb9l7.jsonp' })
  useScript({ src: 'https://fast.wistia.com/assets/external/E-v1.js' })
  return (
    <Fragment>
      <Container>
        <div
          className='wistia_embed wistia_async_hv40ovb9l7'
          style={{
            display: 'inline-block',
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            width: '100%',
            height: '100%',
          }}
        />
      </Container>
      <RyuBreak />
    </Fragment>
  )
}
