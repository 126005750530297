import { Fragment, FunctionComponent, PropsWithChildren } from 'react'

import { RigidPageHeader } from '~/src/components/RigidPageHeader'
import { RigidPage } from '~/src/components/RigidPage'

type Props = PropsWithChildren<{
  wrapInRigidPage: boolean
}>

export const StepWrapperAsFullPage: FunctionComponent<Props> = ({ children, wrapInRigidPage }) => {
  if (!wrapInRigidPage) {
    return <Fragment>{children}</Fragment>
  }

  return (
    <Fragment>
      <RigidPageHeader onLogoClick={null} />

      <RigidPage>{children}</RigidPage>
    </Fragment>
  )
}
