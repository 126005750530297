import { type ChangeEvent, type ReactNode, useMemo } from 'react'
import {
  type FieldPathByValue,
  type FieldValues,
  type UseControllerProps,
  useController,
} from 'react-hook-form'

import {
  RyuFlex,
  type RyuFlexGapSize,
  RyuFlexGrow,
  type RyuFlexProps,
  RyuInputCheckbox,
  type RyuInputCheckboxProps,
  RyuPad,
  RyuTextLabel,
} from '@ramp/ryu'

import { type KenFieldGetCaption, getKenFieldCaption } from './utils'

type KenFieldCheckboxGroupItem = Omit<RyuInputCheckboxProps, 'onChange' | 'value' | 'checked'> & {
  key: string
}

type KenFieldCheckboxGroupOnChange<TItem> = (
  nextValue: TItem[],
  event: ChangeEvent<HTMLInputElement>
) => void

type KenFieldCheckboxGroupProps<
  TFieldValues extends FieldValues = never,
  TName extends FieldPathByValue<TFieldValues, TItem[]> = never,
  TItem = FieldValues[TName][number],
> = {
  items: TItem[]
  parseItem: (item: TItem) => KenFieldCheckboxGroupItem
  direction?: RyuFlexProps['direction']
  caption?: ReactNode
  getCaption?: KenFieldGetCaption
  required?: boolean | string
  gapSize?: RyuFlexGapSize
  onBeforeChange?: KenFieldCheckboxGroupOnChange<TItem>
  onChange?: KenFieldCheckboxGroupOnChange<TItem>
} & UseControllerProps<TFieldValues, TName>

export function KenFieldCheckboxGroup<
  TFieldValues extends FieldValues = never,
  TName extends FieldPathByValue<TFieldValues, TItem[]> = never,
  TItem = FieldValues[TName][number],
>({
  items,
  parseItem,
  caption: consumerCaption,
  getCaption,
  required,
  direction = 'column',
  gapSize = 'xs',
  onBeforeChange,
  onChange,
  ...restProps
}: KenFieldCheckboxGroupProps<TFieldValues, TName, TItem>) {
  const {
    field: { value: rawValue, ...field },
    fieldState: { error },
  } = useController<TFieldValues, TName>({
    ...restProps,
    rules: {
      required: required === true ? 'Select at least one option' : required,
      ...restProps.rules,
    },
  })

  const currentValue = rawValue as TItem[]
  const currentValueKeys = useMemo(
    () => currentValue.map((item) => parseItem(item).key),
    [currentValue, parseItem]
  )

  const caption = getKenFieldCaption({ error, caption: consumerCaption, getCaption })

  return (
    <div>
      <RyuFlex direction={direction} alignItems='stretch' gapSize={gapSize}>
        <RyuFlexGrow>
          {items.map((item) => {
            const { key, label, iconSide = 'left', disabled, onBlur, ...consumerProps } = parseItem(item)

            return (
              <RyuInputCheckbox
                {...consumerProps}
                {...field}
                key={key}
                disabled={disabled}
                checked={currentValueKeys.includes(key)}
                hasError={!!error}
                iconSide={iconSide}
                label={label}
                onChange={(nextChecked, event) => {
                  const nextValue = nextChecked
                    ? [...currentValue, item]
                    : currentValue.filter((currentValueItem) => parseItem(currentValueItem).key !== key)

                  onBeforeChange?.(nextValue, event)

                  if (event?.isDefaultPrevented()) {
                    return
                  }

                  field.onChange(nextValue)
                  onChange?.(nextValue, event)
                }}
                onBlur={(event) => {
                  onBlur?.(event)
                  field.onBlur()
                }}
              />
            )
          })}
        </RyuFlexGrow>
      </RyuFlex>

      {!!caption && (
        <RyuPad sizeLeft='xs' sizeTop='xs'>
          <RyuTextLabel color={getCaptionColor()}>{caption}</RyuTextLabel>
        </RyuPad>
      )}
    </div>
  )

  function getCaptionColor() {
    if (error) {
      return 'destructive'
    }

    return 'hushed'
  }
}
