export interface pollParams<TResult> {
  interval: number
  maxAttempts: number
  condition?: (result: TResult) => boolean
}

export default function pollPromise<TReturnType>(
  promise: () => Promise<TReturnType>,
  params: pollParams<TReturnType>
): Promise<TReturnType> {
  const { interval, maxAttempts, condition } = params
  return new Promise<TReturnType>((resolve, reject) => {
    let attempts = 0

    const poll = () => {
      if (attempts++ >= maxAttempts) {
        reject(new Error('Exceeded max attempts'))
        return
      }

      promise()
        .then((result) => {
          // if condition is not provided, resolve immediately
          if (!condition || condition(result)) {
            resolve(result)
          } else {
            setTimeout(poll, interval) // Schedule the next poll
          }
        })
        .catch(() => {
          // Handle error if needed
        })
    }

    setTimeout(poll, interval) // Schedule the first poll
  })
}
