import { useQuery } from '@tanstack/react-query'
import { unauthedApi } from '../api'

/** @see {@link https://github.com/ramp/core/blob/master/suma/apps/financing_application/schemas.py#:~:text=class-,SalesLeadSignUpSchema} */
export type SalesLeadSignUpModel = {
  id: string
  first_name: string | null
  last_name: string | null
  email: string | null
  source: 'AngelList' | 'Quanta'
}

export function useSalesLeadData(): SalesLeadSignUpModel | undefined {
  const salesLeadId = new URLSearchParams(window.location.search).get('sales_lead_id')
  const { data } = useQuery({
    queryKey: ['sales_lead_data'],
    enabled: !!salesLeadId,
    queryFn: (): Promise<SalesLeadSignUpModel | undefined> => {
      return unauthedApi.get(`application/sales_leads/${salesLeadId}`).json<SalesLeadSignUpModel>()
    },
  })

  return data
}
