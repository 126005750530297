import { createContext } from 'react'
import { LeadContextProps } from './types'

export const LeadContext = createContext<LeadContextProps>({
  lead: null,
  setLead: () => {},
  setHydratedLead: () => {},
  setIsLeadHydrated: () => {},
  isLeadHydrated: false,
})
