import { RyuBreak, RyuGrid, RyuText, RyuButton, RyuInputText, RyuInputCheckboxStack } from '@ramp/ryu'
import { Form, Field } from 'react-final-form'

import { LeadModel } from '~/src/models/leads/LeadModel'
import { required, length } from 'redux-form-validators'
import {
  INTERESTED_PRODUCTS_LABEL_MAP,
  PossibleInterestedProducts,
} from '~/src/components/UsernameSignUp'
import { SalesDemoForm } from '~/src/routes/UserQualification/pages/sales-demo-type'

interface Props {
  lead: LeadModel | null
  initialValues: SalesDemoForm
  onSubmit: (data: SalesDemoForm) => void
}

export function SalesDemoFormInputs({ lead, initialValues, onSubmit }: Props) {
  return (
    <div>
      {lead?.company_email && lead.company_email.length > 0 && (
        <RyuInputText variant='outlined' label='Email' value={lead.company_email} disabled={true} />
      )}
      <Form
        initialValues={initialValues}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <RyuBreak size='s' />

            <RyuGrid columns={2} responsiveColumns={1} gapSize='s'>
              <Field name='first_name' validate={required()}>
                {({ input, meta }) => (
                  <RyuInputText
                    value={input.value}
                    variant='outlined'
                    label='First Name'
                    hasError={meta.error && meta.touched}
                    onChange={input.onChange}
                  />
                )}
              </Field>
              <Field name='last_name' validate={required()}>
                {({ input, meta }) => (
                  <RyuInputText
                    value={input.value}
                    variant='outlined'
                    label='Last Name'
                    hasError={meta.error && meta.touched}
                    onChange={input.onChange}
                  />
                )}
              </Field>
            </RyuGrid>

            <RyuBreak size='s' />

            <Field name='company' validate={required()}>
              {({ input, meta }) => (
                <RyuInputText
                  value={input.value}
                  variant='outlined'
                  label='Company Name'
                  hasError={meta.error && meta.touched}
                  onChange={input.onChange}
                />
              )}
            </Field>

            <RyuBreak size='s' />

            <Field<SalesDemoForm['interested_in_products']>
              name='interested_in_products'
              validate={length({ min: 1, message: 'Select at least one option' })}
            >
              {({ input, meta }) => (
                <RyuInputCheckboxStack<PossibleInterestedProducts>
                  legend={
                    <RyuText.span size='s' color='hushed'>
                      Products you’re interested in
                    </RyuText.span>
                  }
                  error={meta.touched && meta.error}
                  caption='Don’t worry. This is not a commitment to any product.'
                  items={[
                    {
                      label: INTERESTED_PRODUCTS_LABEL_MAP.interested_in_corporate_cards,
                      value: 'interested_in_corporate_cards',
                    },
                    {
                      label: INTERESTED_PRODUCTS_LABEL_MAP.interested_in_bill_payments,
                      value: 'interested_in_bill_payments',
                    },
                    {
                      label: INTERESTED_PRODUCTS_LABEL_MAP.interested_in_procurement,
                      value: 'interested_in_procurement',
                    },
                    {
                      label: INTERESTED_PRODUCTS_LABEL_MAP.interested_in_treasury,
                      value: 'interested_in_treasury',
                    },
                  ]}
                  values={input.value}
                  onChange={input.onChange}
                />
              )}
            </Field>

            <RyuBreak size='xl' />

            <RyuButton
              variant='prime'
              color='primary'
              type='submit'
              fullWidth={true}
              _textAlign='center'
            >
              Book a demo
            </RyuButton>
          </form>
        )}
        onSubmit={onSubmit}
      />
    </div>
  )
}
