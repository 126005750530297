import { type ReactNode, Suspense } from 'react'
import { Helmet } from 'react-helmet-async'

import { RigidForm } from '~/src/components/RigidForm'
import { RigidPage } from '~/src/components/RigidPage'
import { RigidPageContent } from '~/src/components/RigidPageContent'
import { RigidPageHeader } from '~/src/components/RigidPageHeader'

type Props = {
  pageTitle: string
  onLogoClick?: null | (() => void)
  align?: 'center'
  rightContent?: ReactNode
  children: ReactNode
}

export function RigidLayout({ pageTitle, onLogoClick, rightContent, align, children }: Props) {
  return (
    <RigidPage>
      <RigidPageHeader onLogoClick={onLogoClick} />

      <Helmet>
        <title>{pageTitle} — Ramp</title>
      </Helmet>

      <RigidForm rightContent={rightContent} center={align === 'center'}>
        <RigidPageContent>
          <Suspense fallback={null}>{children}</Suspense>
        </RigidPageContent>
      </RigidForm>
    </RigidPage>
  )
}
