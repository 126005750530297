import { type ChangeEvent, type ReactNode } from 'react'
import {
  type FieldPath,
  type FieldPathValue,
  type FieldValues,
  type PathValue,
  type UseControllerProps,
  useController,
} from 'react-hook-form'

import {
  RyuFlex,
  type RyuFlexGapSize,
  RyuFlexGrow,
  type RyuFlexProps,
  RyuInputRadio,
  type RyuInputRadioProps,
  RyuPad,
  RyuTextLabel,
} from '@ramp/ryu'

import { isNotNullish } from '~/src/utils/filters'

import { type KenFieldGetCaption, getKenFieldCaption } from './utils'

type KenFieldRadioGroupItem<TItem> = Omit<RyuInputRadioProps, 'onChange' | 'value' | 'checked'> & {
  value: TItem
}

type KenFieldRadioGroupOnChange<TItem> = (nextValue: TItem, event: ChangeEvent<HTMLInputElement>) => void

type KenFieldRadioGroupProps<
  TFieldValues extends FieldValues = never,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  TItem extends FieldPathValue<TFieldValues, TName> = FieldPathValue<TFieldValues, TName>,
> = {
  items: TItem[]
  parseItem: (item: TItem) => KenFieldRadioGroupItem<TItem>
  direction?: RyuFlexProps['direction']
  caption?: ReactNode
  getCaption?: KenFieldGetCaption
  required?: boolean | string
  gapSize?: RyuFlexGapSize
  onBeforeChange?: KenFieldRadioGroupOnChange<TItem>
  onChange?: KenFieldRadioGroupOnChange<TItem>
} & UseControllerProps<TFieldValues, TName>

export function KenFieldRadioGroup<
  TFieldValues extends FieldValues = never,
  TName extends FieldPath<TFieldValues> = never,
  TItem extends FieldPathValue<TFieldValues, TName> = FieldPathValue<TFieldValues, TName>,
>({
  items,
  parseItem,
  caption: consumerCaption,
  getCaption,
  required: consumerRequired,
  direction = 'column',
  gapSize = 'xs',
  onBeforeChange,
  onChange,
  ...restProps
}: KenFieldRadioGroupProps<TFieldValues, TName, TItem>) {
  const { required: requiredRule, validate: consumerValidate, ...rules } = restProps.rules ?? {}
  const required = requiredRule ?? consumerRequired

  const {
    field,
    fieldState: { error },
  } = useController<TFieldValues, TName>({
    ...restProps,
    rules: {
      ...rules,
      validate: {
        /**
         * We're intentionally using `validate` instead of `required` to support `false` as a valid value.
         * Radios are often booleans, and `react-hook-form` treats `false` as an empty value.
         */
        ...(required ? { validateRequired } : undefined),
        ...(typeof consumerValidate === 'function' ? { validate: consumerValidate } : consumerValidate),
      },
    },
  })

  const caption = getKenFieldCaption({ error, caption: consumerCaption, getCaption })

  return (
    <div>
      <RyuFlex direction={direction} alignItems='stretch' gapSize={gapSize}>
        <RyuFlexGrow>
          {items.map((item) => {
            const {
              value: itemValue,
              label,
              iconSide = 'left',
              disabled,
              onBlur,
              ...consumerProps
            } = parseItem(item)

            return (
              <RyuInputRadio
                {...consumerProps}
                {...field}
                key={String(itemValue)}
                disabled={disabled}
                checked={field.value === itemValue}
                hasError={!!error}
                iconSide={iconSide}
                label={label}
                onChange={(nextChecked, event) => {
                  onBeforeChange?.(itemValue, event)

                  if (event?.isDefaultPrevented()) {
                    return
                  }

                  field.onChange(itemValue)
                  onChange?.(itemValue, event)
                }}
                onBlur={(event) => {
                  onBlur?.(event)
                  field.onBlur()
                }}
              />
            )
          })}
        </RyuFlexGrow>
      </RyuFlex>

      {!!caption && (
        <RyuPad sizeLeft='xs' sizeTop='xs'>
          <RyuTextLabel color={getCaptionColor()}>{caption}</RyuTextLabel>
        </RyuPad>
      )}
    </div>
  )

  function getCaptionColor() {
    if (error) {
      return 'destructive'
    }

    return 'hushed'
  }

  function validateRequired(value: PathValue<TFieldValues, TName>) {
    if (isNotNullish(value)) {
      return
    }

    if (typeof required === 'string') {
      return required
    }

    return 'Select an option'
  }
}
