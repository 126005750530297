const { NODE_ENV, REACT_APP_ENV } = import.meta.env

export const isDevelopment = NODE_ENV === 'development'

export const isQA = REACT_APP_ENV === 'qa' // QA Instance (qa.ramp.com)

export const isDemo = REACT_APP_ENV === 'demo' // Sales Demo Instance (demo.ramp.com)

export const isProduction = REACT_APP_ENV === 'prd'

export const baseWebUrl = REACT_APP_ENV === 'prd' ? 'https://ramp.com' : 'https://qa-web.ramp.com'
