import ky from 'ky'

export const API_URL = import.meta.env.REACT_APP_API_URL
export const API_V1_URL = `${API_URL}v1`
export const DEFAULT_REQUEST_TIMEOUT = 15000

export const unauthedApi = ky.extend({
  prefixUrl: `${API_V1_URL}/public`,
  credentials: 'include',
  timeout: DEFAULT_REQUEST_TIMEOUT,
})
