import { Fragment, FunctionComponent } from 'react'

import { RyuBreak, RyuFlex, RyuProgressBar, RyuText } from '@ramp/ryu'

import { RigidPageContent } from '~/src/components/RigidPageContent'

import { BackButton } from '../BackButton'
import { RadioPicker } from '../RadioPicker'
import { StepHeader } from '../StepHeader'

type Props = {
  onChange: (value: string) => void
  onClickedBack: () => void
}

export const MonthlySpend: FunctionComponent<Props> = ({ onChange, onClickedBack }) => {
  return (
    <Fragment>
      <RyuProgressBar value={50} size='l' color='accent' />
      <RigidPageContent>
        <RyuText align='center' color='hushed' size='l'>
          Is Ramp right for your business?
        </RyuText>

        <StepHeader>What’s your current monthly business card spend?</StepHeader>
        <RyuBreak />
        <RadioPicker
          options={[
            {
              label: 'Under $10,000/mo',
              value: '<10k',
            },
            {
              label: '$10,000 - $50,000/mo',
              value: '10k-50k',
            },
            {
              label: '$50,000 - $250,000/mo',
              value: '50k-250k',
            },
            {
              label: 'Over $250,000/mo',
              value: '250k+',
            },
          ]}
          onChange={(value) => {
            onChange(value)
          }}
        />
        <RyuBreak size='l' />
        <RyuFlex>
          <BackButton onClick={onClickedBack} />
        </RyuFlex>
      </RigidPageContent>
    </Fragment>
  )
}
