import { demoFallbackLink, thanksLink } from './bookingRouteURLs'

export function redirect(url: string) {
  window.location.replace(url)
}

export function redirectToDemoFallback(email?: string) {
  const URIEncodedEmail = encodeURIComponent(email ?? '')
  const demoLink = email ? `${demoFallbackLink}?email=${URIEncodedEmail}` : demoFallbackLink
  redirect(demoLink)
}

export function redirectToThanks() {
  redirect(thanksLink)
}
