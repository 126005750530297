import { styled } from 'styled-components'

import { Fragment, useEffect, useState } from 'react'

import { RyuBreak, RyuFlex, RyuPad, RyuText, useRyuBreakpoints } from '@ramp/ryu'

import { KenRampLogo } from '~/src/components/ken/KenRampLogo'
import { useKenSearchParams } from '~/src/hooks/useKenSearchParams'
import { UsernameSignUp } from '~/src/components/UsernameSignUp'

import { GoogleTagManager } from '~/src/scripts/gtm'
import { isProduction } from '~/src/utils/env'
import { trackEvent } from '~/src/utils/analytics'
import { FullScreenConfetti } from '~/src/routes/UserQualification/FullScreenConfetti'

import { pageProperties } from '~/src/utils/analytics/pageEnrichment'
import { TestimonialCarouselSidebar } from '~/src/routes/UserQualification/TestimonialCarouselSidebar'

export function SelfService() {
  const [{ email: leadEmail, first_name: leadFirstName, last_name: leadLastName, invited = false }] =
    useKenSearchParams({
      schema: { email: 'string', first_name: 'string', last_name: 'string', invited: 'boolean' },
    })

  useEffect(() => {
    if (!leadEmail) {
      return
    }

    trackEvent('Self Service Page Viewed', { email: leadEmail, ...pageProperties() })
    trackEvent('SS CS DR VE Total Page Viewed', { email: leadEmail, ...pageProperties() })
  }, [leadEmail])
  const [hideClaimAccountMessage, setHideClaimAccountMessage] = useState(false)

  const isMobile = useRyuBreakpoints({ maxWidth: 'tablet' })
  const isDesktop = useRyuBreakpoints({ minWidth: 'desktop' })
  const [{ location }] = useKenSearchParams({
    schema: { location: 'string' },
  })

  const isFromSalesQualified = location === 'sales-qualified'

  return (
    <Fragment>
      {isProduction && <GoogleTagManager />}

      <FullScreenConfetti />

      <SelfServiceLayout>
        <SelfServiceFormLayout>
          <SelfServiceFormLayoutContent $isMobile={isMobile}>
            <RyuPad size='m'>
              <RyuBreak size='xxl' />
              <RyuBreak size='xxl' />

              <RyuFlex justifyContent='center'>
                <KenRampLogo />
              </RyuFlex>

              <RyuBreak size='xl' />

              <RyuText.h size='l' paddingVertical='none' align='center'>
                {getWelcomeText()}
              </RyuText.h>

              {!hideClaimAccountMessage && (
                <Fragment>
                  <RyuBreak />

                  {!!getSubText() && (
                    <RyuText.div size='s' color='hushed' align='center'>
                      {getSubText()}
                    </RyuText.div>
                  )}

                  <RyuBreak size='xl' />
                </Fragment>
              )}

              <UsernameSignUp
                leadEmail={leadEmail}
                leadFirstName={leadFirstName}
                leadLastName={leadLastName}
                onLeadEmailMatchesExistingIdentity={() => setHideClaimAccountMessage(true)}
              />

              <RyuBreak />
            </RyuPad>
          </SelfServiceFormLayoutContent>
        </SelfServiceFormLayout>

        {isDesktop && <TestimonialCarouselSidebar />}
      </SelfServiceLayout>
    </Fragment>
  )

  function getWelcomeText(): string {
    if (isFromSalesQualified) {
      return 'We’re looking forward to connecting.'
    }

    if (invited) {
      return 'You’re invited to apply!'
    }

    return 'Claim your account'
  }

  function getSubText(): string | null {
    if (isFromSalesQualified) {
      return 'In the meantime, secure your credit limit and get access to the Ramp platform.'
    }

    return 'Complete your application in as little as 10 minutes.'
  }
}

const SelfServiceLayout = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex: 0 0 100%;
`

const SelfServiceFormLayout = styled.div`
  flex: 2;
  display: flex;
  justify-content: center;
`

const SelfServiceFormLayoutContent = styled.div<{ $isMobile: boolean }>`
  min-width: ${({ $isMobile }) => `${$isMobile ? '100%' : '45rem'}`};
`
