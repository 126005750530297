import { FunctionComponent, useEffect } from 'react'

import {
  RyuBreak,
  RyuText,
  RyuButton,
  RyuPad,
  RyuFlex,
  useRyuBreakpoints,
  RyuImage,
  RyuIcon,
  useRyuThemeState,
} from '@ramp/ryu'

import { FullScreenConfetti } from '../FullScreenConfetti'
import { styled } from 'styled-components'
import { LeadModel } from '~/src/models/leads/LeadModel'
import contactSalesAvatars from '~/src/images/contact-sales-avatars.png'
import customerLogoFooter from '~/src/images/customer-logo-footer.png'
import customerLogoFooterDark from '~/src/images/customer-logo-footer-dark.png'

import contactSalesAvatarStack from '~/src/images/contact-sales-avatar-stack.png'
import { KenStack } from '~/src/components/ken/KenStack'
import { HairlineDividerWithText } from '~/src/components/HairlineDividerWithText'
import { SalesDemoFormInputs } from './SalesDemoFormInputs'
import { SalesDemoForm } from '~/src/routes/UserQualification/pages/sales-demo-type'
import { trackEvent } from '~/src/utils/analytics'

type Props = {
  onSubmit: (data: SalesDemoForm) => void
  onOverride: () => void
  bookedMeeting?: boolean
  initialValues: SalesDemoForm
  lead: LeadModel | null
}

export const SalesDemoControl: FunctionComponent<Props> = ({
  onSubmit,
  onOverride,
  bookedMeeting,
  initialValues,
  lead,
}) => {
  const [theme] = useRyuThemeState()

  const isDesktop = useRyuBreakpoints({ minWidth: 'desktop' })

  useEffect(() => {
    trackEvent('Qualified Chat Bot Experiment Control V2 Viewed')
  }, [])

  if (bookedMeeting) {
    onOverride()
  }

  return (
    <SalesDemoLayout>
      <FullScreenConfetti />

      <RyuBreak size='xl' />

      <RyuFlex gapSize='xl' alignItems='center' responsive={true}>
        <RyuPad size='l'>
          <div>
            {!isDesktop && <ContactSalesAvatarStack src={contactSalesAvatarStack} />}

            <RyuText.h2 align={isDesktop ? 'center' : 'left'} size='l' paddingVertical='none'>
              Let's get you connected with a Ramp expert
            </RyuText.h2>

            <RyuBreak size='s' />

            <RyuText.p align={isDesktop ? 'center' : 'left'} color='hushed'>
              See the full power of the Ramp platform in 30 minutes
            </RyuText.p>

            <RyuBreak />

            <SalesDemoFormInputs lead={lead} initialValues={initialValues} onSubmit={onSubmit} />

            <RyuBreak size='m' />

            <HairlineDividerWithText text='or' />

            <RyuBreak size='m' />

            <RyuButton
              fullWidth={true}
              iconSide='right'
              iconType='arrow-right'
              href='/sign-up'
              _textAlign='center'
              onClick={() => {
                trackEvent('Qualified Chat Bot Experiment Control V2 Clicked', { event_type: 'sign-up' })
              }}
            >
              Apply for Ramp
            </RyuButton>
          </div>
        </RyuPad>

        <RyuPad size='l'>
          {isDesktop && <RyuImage src={contactSalesAvatars} />}

          <RyuBreak as='hr' size='xl' />

          <RyuText.h4 paddingVertical='none'>What can you expect?</RyuText.h4>

          <RyuBreak size='l' />

          <KenStack gapSize='m'>
            <RyuFlex gapSize='s'>
              <RyuIcon fill={true} type='help-circle' size='l' />
              <RyuText.span>Answers to any questions you have about Ramp</RyuText.span>
            </RyuFlex>

            <RyuFlex gapSize='s'>
              <RyuIcon fill={true} type='dollar-sign' size='l' />
              <RyuText.span>A custom assessment to see how much Ramp will save you</RyuText.span>
            </RyuFlex>

            <RyuFlex gapSize='s'>
              <RyuIcon fill={true} type='merge' size='l' />
              <RyuText.span>Information on how Ramp can consolidate your financial systems</RyuText.span>
            </RyuFlex>
          </KenStack>
        </RyuPad>
      </RyuFlex>

      <RyuBreak size='xl' />

      {isDesktop && (
        <RyuPad size='s'>
          <RyuImage src={theme === 'ryu-dark' ? customerLogoFooterDark : customerLogoFooter} />
        </RyuPad>
      )}
    </SalesDemoLayout>
  )
}

const SalesDemoLayout = styled.div`
  max-width: 90rem;
  margin: 0 auto;
  height: calc(100vh - 10rem);
  position: relative;
`

const ContactSalesAvatarStack = styled.img`
  max-width: 10rem;
`
