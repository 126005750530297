import * as Sentry from '@sentry/browser'
import { type CaptureContext, type ScopeContext } from '@sentry/types'
import { HTTPError, TimeoutError } from 'ky'
import LogRocket from 'logrocket'

import { isProduction, isQA } from '~/src/utils/env'

/**
 * Checks whether `error` is a ky `HTTPError`.
 * If a `code` is provided, it will also verify that it matches.
 */
export function isHttpError(error: unknown, code?: number): error is HTTPError {
  if (!(error instanceof HTTPError)) {
    return false
  }

  if (code !== undefined) {
    return error.response.status === code
  }

  return true
}

/**
 * Supported developer-set sentry tags
 *
 * If you want to add auxillary information to help debug, please use the
 * @extras parameter for {@link logError}
 *
 * All other relevant tags are automatically collected. Ramp tags should be set when
 * assigning specific owners, overriding channels, etc.
 */
type RampTag = 'owner' | 'channel' | 'errorBoundaryShown'

/**
 * Enum representing the different required response levels for an error.
 *
 *  Please note that critical errors WILL (eventually) trigger pagers and alerts and should
 *  be used sparingly.
 */
export enum URGENCY {
  /** Requires immediate triage and review. */
  CRITICAL = 'critical',
  /** Requires triage or review within a few hours, latest EOD */
  VERYHIGH = 'veryHigh',
  /** Requires triage or review by EOD */
  HIGH = 'high',
  /** Should have triage or review by EOW/when available */
  MEDIUM = 'medium',
  /** Can triage or review by EOW. Consider if this should be ignored */
  LOW = 'low',
  /** Can almost certainly be ignored. Used as input for metrics. */
  VERYLOW = 'veryLow',
}

type TagParamReturnType = string | boolean
type TagParams = Record<RampTag, TagParamReturnType>

/**
 * Convert all fields to optional, but must be defined. In other words,
 * given parameter M and type T, M \subset T.
 *
 * Examples:
 * - interface A {a: number, b: number}
 * - obj U = {a: 20}
 * - obj V = {a: undefined}
 *
 *
 * - PartialNoUndefined<T implements A, A> -> OK
 * - PartialNoUndefined<U, A> -> OK
 * - PartialNoUndefined<V, A> -> NOT OK (a is undefined)
 *
 * Authors Note: This took way too long to figure out
 */
type PartialNoUndefined<TScopeContext, TParams> = {
  [TKey in keyof TScopeContext]: TKey extends keyof TParams ? Required<TParams>[TKey] : never
}

interface BaseErrorTags<TScopeContext> extends Partial<ScopeContext> {
  /** Override sentry level. Errors will have level "error" by default */
  sentryLevel?: Sentry.Severity
  /**
   * Set of tags to pass to Sentry. These are used in error management.
   *
   * Currently allowed tags: {@link RampTag}
   */
  tags?: PartialNoUndefined<TScopeContext, TagParams>
  /**
   * Optional set of additional tags to pass into sentry for additional context.
   * These are not used in error management, but are nice to have for context.
   */
  extra?: Record<string, any>
}

/**
 * Optional parameters to provide more context to Sentry.
 *
 * Passing in additional params helps organize slack messages,
 * prioritize critical issues, etc.
 *
 * Will become mandatory eventually.
 */
type LogParams<TScopeContext> = BaseErrorTags<TScopeContext> & { urgency: URGENCY }

function transformScope<TScopeContext>({
  sentryLevel,
  urgency,
  tags,
  ...params
}: LogParams<TScopeContext>): CaptureContext {
  return {
    tags: { ...tags, urgency },
    ...(sentryLevel && { level: sentryLevel }),
    ...params,
  }
}

// Helper method to conditionally add parameters. This should eventually be
// mandatory once all error handling is completely refactored.
function sentryCaptureWithParams<TScopeContext>(
  error: Error | string,
  params?: LogParams<TScopeContext>
): string {
  if (typeof error === 'string') {
    return Sentry.captureMessage(error, params && (transformScope(params) as any))
  }

  return Sentry.captureException(error, params && (transformScope(params) as any))
}

/**
 * Utility method to wrap sentry error handling and inject additional information.
 *
 * @param error - Error object or message to pass in.
 * @param params - Optional parameters to provide more context to Sentry.
 */
function logErrorUtil<TScopeContext>(
  error: Error | string,
  params?: LogParams<TScopeContext>
): string | undefined {
  console.error(error)

  if (isProduction || isQA) {
    if (typeof error === 'string') {
      LogRocket.captureMessage(error)
    } else {
      LogRocket.captureException(error)
    }

    return sentryCaptureWithParams(error, params)
  }
}

const logErrorWrapper =
  (urgency: URGENCY) =>
  <TTag>(error: Error | string, params?: BaseErrorTags<TTag>): string | undefined =>
    logErrorUtil(error, { ...params, urgency })

/**
 * Utility method to wrap sentry error handling and inject additional information.
 *
 * Call using {@code logError.{severity}(error, [params])}
 *
 * @param error - Error object or message to pass in.
 * @param params - Optional parameters to provide more context to Sentry.
 */
export const logError = {
  /** Requires immediate triage and review. */
  [URGENCY.CRITICAL]: logErrorWrapper(URGENCY.CRITICAL),
  /** Requires triage or review within a few hours, latest EOD */
  [URGENCY.VERYHIGH]: logErrorWrapper(URGENCY.VERYHIGH),
  /** Requires triage or review by EOD */
  [URGENCY.HIGH]: logErrorWrapper(URGENCY.HIGH),
  /** Should have triage or review by EOW/when available */
  [URGENCY.MEDIUM]: logErrorWrapper(URGENCY.MEDIUM),
  /** Can triage or review by EOW. Consider if this should be ignored */
  [URGENCY.LOW]: logErrorWrapper(URGENCY.LOW),
  /** Can almost certainly be ignored. Used as input for metrics. */
  [URGENCY.VERYLOW]: logErrorWrapper(URGENCY.VERYLOW),
}

export enum ErrorCode {
  BILLING_NO_ACTIVE_SUBSCRIPTIONS = 'BILLING_7001',
  BILLING_NO_STATEMENT_AVAILABLE = 'BILLING_7003',
  BILLING_SUBSCRIPTION_ALREADY_ACTIVE = 'BILLING_7002',
  BILL_PAY_INVALID_NETWORK_VENDOR_EMAIL = 'BILL_PAY_7017',
  PROFILE_EXPIRED_OOB_CODE = 'PROFILE_7016',
  PROFILE_INVALID_OOB_CODE = 'PROFILE_7017',
  TIMEOUT = 'TIMEOUT',
  TWILIO_NUM_UNSUBSCRIBED = '6020',
  TWILIO_NUM_OUT_OF_REGION = '6019',
}

type ErrorMeta<TErrorType> = {
  endpoint: string | null
  errorCode: ErrorCode | TErrorType | null
  errorMessage: string
}

type ParseHttpResponseErrorOptions = {
  /**
   *
   * @deprecated
   */
  defaultErrorMessage?: string
}

export async function parseHttpResponseError<TErrorType extends string | null = null>(
  error: unknown,
  options: ParseHttpResponseErrorOptions = {}
): Promise<ErrorMeta<TErrorType>> {
  const { defaultErrorMessage = 'There was an error.' } = options
  if (error instanceof TimeoutError) {
    return {
      endpoint: getEndpoint(error.request),
      errorCode: ErrorCode.TIMEOUT,
      errorMessage: 'Sorry, this request timed out. Please refresh the page to try again.',
    }
  }

  if (error instanceof HTTPError) {
    try {
      const responseBody = await error.response.clone().json()

      return {
        endpoint: getEndpoint(error.request),
        errorCode: responseBody.error_v2?.error_code,
        errorMessage: responseBody.error_v2?.message ?? defaultErrorMessage,
      }
    } catch {
      // fall through to generic error message
    }
  }

  return {
    errorCode: null,
    errorMessage: defaultErrorMessage,
    endpoint: null,
  }

  /**
   *
   * @example
   * getEndpoint(new Request('https://api.ramp.com/v1/auth/endpoint?foo=bar'))
   * // '/v1/auth/endpoint'
   */
  function getEndpoint(request: Request) {
    return new URL(request.url).pathname
  }
}
