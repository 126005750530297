import { styled, type CSSProperties } from 'styled-components'

import { RyuBreak, RyuText } from '@ramp/ryu'

interface TestimonialContainerProps {
  narrow?: boolean
}

const TestimonialContainer = styled.aside<TestimonialContainerProps>`
  max-width: ${({ narrow }) => (narrow ? '50rem' : '60rem')};

  position: sticky;
  top: 0;
  right: 0;

  display: flex;
  flex-direction: column;
  align-items: stretch;
`

const TestimonialImage = styled.img<{ $objectPosition: CSSProperties['objectPosition'] }>`
  width: 100%;
  height: 80%;
  position: relative;
  top: 0;
  left: 0;
  min-height: 0;
  object-fit: cover;
  object-position: ${(props) => props.$objectPosition ?? 'center center'};
  /* Prevents layout shift while the image is loading */
  aspect-ratio: calc(583 / 711);
  border-radius: ${({ theme }) => theme.ryuSpacingSizes.s}rem;
`

const TestimonialContent = styled.div`
  position: absolute;
  bottom: 0;
  padding: ${(props) => props.theme.ryuSpacingSizes.xl}rem;
  color: white;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
  border-radius: ${(props) => props.theme.ryuSpacingSizes.s}rem;
`

export type QualifiedTestimonialProps = {
  description: string
  imageUrl: string
  quoteAuthorName: string
  quoteAuthorTitle: string
  companyName: string
  companyLogoUrl: string
  narrow?: boolean
  imagePosition?: CSSProperties['objectPosition']
}

export function Testimonial(props: QualifiedTestimonialProps) {
  return (
    <TestimonialContainer narrow={props.narrow}>
      <TestimonialImage src={`${props.imageUrl}`} $objectPosition={props.imagePosition} />

      <TestimonialContent>
        <img src={props.companyLogoUrl} height={20} alt='Company Logo' />

        <RyuBreak />

        <RyuText.p size='l' marginBottom={false}>
          {props.description}
        </RyuText.p>

        <RyuBreak />

        <RyuText.p size='s' marginBottom={false}>
          {props.quoteAuthorName}
        </RyuText.p>

        <RyuText.p size='s' marginBottom={false}>
          {props.quoteAuthorTitle}, {props.companyName}
        </RyuText.p>

        <RyuBreak />
      </TestimonialContent>
    </TestimonialContainer>
  )
}
