import * as Sentry from '@sentry/browser'
import { CaptureContext, ScopeContext } from '@sentry/types'

import { isProduction, isQA } from '~/src/utils/env'

/** Supported developer-set sentry tags
 *
 * If you want to add auxillary information to help debug, please use the
 * @extras parameter for {@link logError}
 *
 * All other relevant tags are automatically collected. Ramp tags should be set when
 * assigning specific owners, overriding channels, etc.
 */
type RampTag = 'owner' | 'channel' | 'errorBoundaryShown'

/** Enum representing the different required response levels for an error.
 *
 *  Please note that critical errors WILL (eventually) trigger pagers and alerts and should
 *  be used sparingly.
 */
export enum URGENCY {
  // TODO(jsarihan): add note for pagers once enabled
  /** Requires immediate triage and review. */
  CRITICAL = 'critical',
  /** Requires triage or review within a few hours, latest EOD */
  VERYHIGH = 'veryHigh',
  /** Requires triage or review by EOD */
  HIGH = 'high',
  /** Should have triage or review by EOW/when available */
  MEDIUM = 'medium',
  /** Can triage or review by EOW. Consider if this should be ignored */
  LOW = 'low',
  /** Can almost certainly be ignored. Used as input for metrics. */
  VERYLOW = 'veryLow',
}

// // eslint-disable-next-line no-redeclare
// TODO(jsarihan): redeclare namespace and add utility methods
// export declare namespace Urgency {
//   /**
//    * Converts a string-based level into a {@link Urgency}.
//    *
//    * @param level string representation of Urgency
//    * @returns Urgency
//    */

type TagParamReturnType = string | boolean
type TagParams = { [Key in RampTag]: TagParamReturnType }

/** Convert all fields to optional, but must be defined. In other words,
given parameter M and type T, M \subset T.
 *
 * Examples:
 * - interface A {a: number, b: number}
 * - obj U = {a: 20}
 * - obj V = {a: undefined}
 *
 *
 * - PartialNoUndefined<T implements A, A> -> OK
 * - PartialNoUndefined<U, A> -> OK
 * - PartialNoUndefined<V, A> -> NOT OK (a is undefined)
 *
 * Authors Note: This took way too long to figure out
 **/
type PartialNoUndefined<TScopeContext, TParams> = {
  [TKey in keyof TScopeContext]: TKey extends keyof TParams ? Required<TParams>[TKey] : never
}

interface BaseErrorTags<TScopeContext> extends Partial<ScopeContext> {
  /** Override sentry level. Errors will have level "error" by default */
  sentryLevel?: Sentry.Severity
  /** Set of tags to pass to Sentry. These are used in error management.
   *
   * Currently allowed tags: {@link RampTag}
   */
  tags?: PartialNoUndefined<TScopeContext, TagParams>
  /** Optional set of additional tags to pass into sentry for additional context.
   * These are not used in error management, but are nice to have for context.
   */
  extra?: { [key: string]: any }
}

/** Optional parameters to provide more context to Sentry.
 *
 * Passing in additional params helps organize slack messages,
 * prioritize critical issues, etc.
 *
 * Will become mandatory eventually.
 */
export type LogParams<TScopeContext> = BaseErrorTags<TScopeContext> & { urgency: URGENCY }

const createTags = <TScopeContext>(urgency: URGENCY, tags?: BaseErrorTags<TScopeContext>['tags']) => {
  const updatedTags: { [key: string]: TagParamReturnType } = tags ?? {}
  updatedTags.urgency = urgency

  return updatedTags
}

const transformScope = <TScopeContext>({
  sentryLevel,
  urgency,
  tags,
  ...params
}: LogParams<TScopeContext>): CaptureContext => {
  // Transform tags record to include urgency
  const updatedTags = createTags(urgency, tags)

  // Conditionally pass in level as override (most will be error)
  return {
    tags: updatedTags,
    ...(sentryLevel && { level: sentryLevel }),
    ...params,
  }
}

// Helper method to conditionally add parameters. This should eventually be
// mandatory once all error handling is completely refactored.
const sentryCaptureWithParams = <TScopeContext>(
  error: any,
  params?: LogParams<TScopeContext>
): string => {
  // @ts-ignore
  return Sentry.captureException(error, params && transformScope(params))
}

/**
 * Utility method to wrap sentry error handling and inject additional information.
 *
 * @param error - Error object or message to pass in.
 * @param params - Optional parameters to provide more context to Sentry.
 */
const logErrorUtil = <TScopeContext>(
  error: any,
  params?: LogParams<TScopeContext>
): string | undefined => {
  console.error(error)

  if (isProduction || isQA) {
    return sentryCaptureWithParams(error, params)
  }
}

const logErrorWrapper =
  (urgency: URGENCY) =>
  <TTag>(error: any, params?: BaseErrorTags<TTag>): string | undefined =>
    logErrorUtil(error, { ...params, urgency })

/**
 * Utility method to wrap sentry error handling and inject additional information.
 *
 * Call using {@code logError.{severity}(error, [params])}
 *
 * @param error - Error object or message to pass in.
 * @param params - Optional parameters to provide more context to Sentry.
 */
export const logError = {
  /** Requires immediate triage and review. */
  [URGENCY.CRITICAL]: logErrorWrapper(URGENCY.CRITICAL),
  /** Requires triage or review within a few hours, latest EOD */
  [URGENCY.VERYHIGH]: logErrorWrapper(URGENCY.VERYHIGH),
  /** Requires triage or review by EOD */
  [URGENCY.HIGH]: logErrorWrapper(URGENCY.HIGH),
  /** Should have triage or review by EOW/when available */
  [URGENCY.MEDIUM]: logErrorWrapper(URGENCY.MEDIUM),
  /** Can triage or review by EOW. Consider if this should be ignored */
  [URGENCY.LOW]: logErrorWrapper(URGENCY.LOW),
  /** Can almost certainly be ignored. Used as input for metrics. */
  [URGENCY.VERYLOW]: logErrorWrapper(URGENCY.VERYLOW),
}

// type ErrorLogger = <T>(error: any, params?: BaseErrorTags<T>) => void;
// Apparently adding types breaks the jsdoc...
// For the curious logError : Record<URGENCY, ErrorLogger>
