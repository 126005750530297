import { useFormContext } from 'react-hook-form'

import { RyuButton, type RyuButtonProps } from '@ramp/ryu'

type KenFormSubmitButtonProps = Omit<RyuButtonProps, 'as' | 'asProps' | 'type'> & {
  isSubmitting?: boolean
}

export function KenFormSubmitButton({
  disabled,
  iconType,
  iconSide,
  isSubmitting: consumerIsSubmitting,
  ...buttonProps
}: KenFormSubmitButtonProps) {
  const {
    formState: { isSubmitting: formStateIsSubmitting },
  } = useFormContext()

  const isSubmitting = consumerIsSubmitting ?? formStateIsSubmitting

  return (
    <RyuButton
      {...buttonProps}
      type='submit'
      iconType={isSubmitting ? 'loading' : iconType}
      iconSide={iconSide}
      disabled={isSubmitting || disabled}
    />
  )
}
