import { styled } from 'styled-components'

import useDebouncedCallback from '@restart/hooks/useDebouncedCallback'
import ky from 'ky'
import {
  type ControllerRenderProps,
  type FieldError,
  type FieldPath,
  type FieldValues,
  type UseControllerProps,
  useController,
  useFormContext,
} from 'react-hook-form'

import { RyuExpandable, RyuIcon, type RyuInputTextProps, RyuText } from '@ramp/ryu'

import { isHttpError } from '~/src/utils/errors'

import { KenInputPassword } from './KenInputPassword'
import { useState } from 'react'

const MIN_PASSWORD_LENGTH = 12
const DEBOUNCE_INTERVAL_MS = 500
const commonlyUsedPasswordsMap = new Map<string, boolean>()

type PasswordValidatorError =
  | 'required'
  | 'minLength'
  | 'lowercase'
  | 'uppercase'
  | 'number'
  | 'commonlyUsed'

type KenFieldTextProps<
  TFieldValues extends FieldValues = never,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  emptyFieldLabel?: string
} & Omit<UseControllerProps<TFieldValues, TName>, 'rules'> &
  Omit<
    RyuInputTextProps,
    | Exclude<keyof ControllerRenderProps<FieldValues, FieldPath<FieldValues>>, 'onBlur'>
    | 'required'
    | 'caption'
  >

/**
 *
 * `KenPassword` should be used within a RHF form with `criteriaMode: 'all'`
 */
export function KenFieldPassword<
  TFieldValues extends FieldValues = never,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  label = 'Password',
  emptyFieldLabel,
  name,
  onBlur,
  ...restProps
}: KenFieldTextProps<TFieldValues, TName>) {
  const [showPasswordCaption, setShowPasswordCaption] = useState(false)
  const debouncedCommonlyUsedPassword = useDebouncedCommonlyUsedPassword({ name })
  const { trigger } = useFormContext()

  const {
    field,
    fieldState: { error },
    formState: { isDirty },
  } = useController<TFieldValues, TName>({
    ...restProps,
    name,
    rules: {
      required: true,
      minLength: MIN_PASSWORD_LENGTH,
      validate: {
        lowercase: (value) => (/[a-z]/g.test(value) ? undefined : 'Error'),
        uppercase: (value) => (/[A-Z]/g.test(value) ? undefined : 'Error'),
        number: (value) => (/\d/g.test(value) ? undefined : 'Error'),
        commonlyUsed: (value) => (commonlyUsedPasswordsMap.get(value) ? 'Error' : undefined),
      },
    },
  })

  const isNullishValue = !field.value.length

  return (
    <KenInputPassword
      {...restProps}
      {...field}
      name={name}
      label={isNullishValue ? emptyFieldLabel ?? label : label}
      required={true}
      caption={
        <KenFieldPasswordCaption error={error} isExpanded={showPasswordCaption} isDirty={isDirty} />
      }
      hasError={!!error}
      autoComplete='new-password'
      onChange={(nextValue) => {
        field.onChange(nextValue)
        trigger(name)
        debouncedCommonlyUsedPassword(nextValue)
      }}
      onBlur={(event) => {
        onBlur?.(event)
        field.onBlur()
        setShowPasswordCaption(false)
      }}
      onFocus={() => setShowPasswordCaption(true)}
    />
  )
}

function KenFieldPasswordCaption({
  error,
  isExpanded,
  isDirty = false,
}: {
  error?: FieldError
  isExpanded: boolean
  isDirty?: boolean
}) {
  const shouldValidate = isDirty || !!error
  const requiredError = !!error?.types?.required

  return (
    <RyuExpandable expanded={isExpanded}>
      {validationLabelMap.map(({ label, validator }) => {
        const isInvalid = requiredError || !!error?.types?.[validator]
        const color = isInvalid ? 'destructive' : 'constructive'
        const icon = isInvalid ? 'x-square' : 'check-square'

        return (
          <ChecklistRoot key={label}>
            <RyuText.p size='xs' color={shouldValidate ? color : 'hushed'}>
              <RyuIcon type={shouldValidate ? icon : 'square'} size='s' /> {label}
            </RyuText.p>
          </ChecklistRoot>
        )
      })}
    </RyuExpandable>
  )
}

/**
 * Check for safe password is async while other validators are sync.
 *
 * To avoid having any lag on the UI, we trigger this validation separately on the `onChange` event.
 * Once it finishes, it will trigger form validation (aka `passwordValidator`) which will read from
 * the updated `commonlyUsedPasswordsMap`.
 */
function useDebouncedCommonlyUsedPassword({ name }: { name: string }) {
  const { trigger } = useFormContext()

  return useDebouncedCallback(async (password: string) => {
    const currentValue = commonlyUsedPasswordsMap.get(password)

    if (currentValue === true) {
      return
    }

    try {
      const passwordBreach = await checkPasswordBreach(password)

      commonlyUsedPasswordsMap.set(password, passwordBreach !== undefined)
      trigger(name)
    } catch {}
  }, DEBOUNCE_INTERVAL_MS)
}

const validationLabelMap: { label: string; validator: PasswordValidatorError }[] = [
  { label: `At least ${MIN_PASSWORD_LENGTH} characters`, validator: 'minLength' },
  { label: 'At least 1 lowercase character', validator: 'lowercase' },
  { label: 'At least 1 uppercase character', validator: 'uppercase' },
  { label: 'At least 1 number', validator: 'number' },
  { label: 'Not a commonly used password', validator: 'commonlyUsed' },
]

const ChecklistRoot = styled.div`
  .RyuIconSvg--inline {
    vertical-align: text-top;
  }
`

const BANNED_BREACHED_PASSWORD = 'banned-breached-password'

async function checkPasswordBreach(password: string): Promise<string | undefined> {
  const passwordSha1 = await insecureSha1(password)
  const firstFive = passwordSha1.slice(0, 5)
  let breachCount = 0

  try {
    const response = await ky
      .get(`https://api.pwnedpasswords.com/range/${firstFive}`, {
        headers: {
          'add-padding': 'true',
        },
      })
      .text()

    for (const item of response.split('\r\n')) {
      const [suffix, count] = item.split(':')
      if (`${firstFive}${suffix}`.toUpperCase() === passwordSha1.toUpperCase()) {
        breachCount = Number(count)
        break
      }
    }
  } catch (error) {
    if (isHttpError(error)) {
      return undefined
    }

    throw error
  }

  if (breachCount > 10) {
    return BANNED_BREACHED_PASSWORD
  }

  return undefined
}

async function insecureSha1(password: string): Promise<string> {
  const encoder = new TextEncoder()
  const data = encoder.encode(password)
  const hashBuffer = await crypto.subtle.digest('SHA-1', data)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHex = hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('')
  return hashHex
}
