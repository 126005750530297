import { FunctionComponent, useContext, useEffect, useMemo } from 'react'
import { pageProperties } from '~/src/utils/analytics/pageEnrichment'

import { LeadContext } from '~/src/models/leads/LeadModel'
import { trackEvent } from '~/src/utils/analytics'
import { SalesDemoControl } from './SalesDemoControl'
import { SalesDemoTreatment } from './SalesDemoTreatment'
import { useRyuBreakpoints } from '@ramp/ryu'
import { isWithinESTBusinessHours } from '~/src/utils/isWithinESTBusinessHours'
import { SalesDemoForm } from '~/src/routes/UserQualification/pages/sales-demo-type'

type Props = {
  onSubmit: (data: SalesDemoForm) => void
  onOverride: () => void
  bookedMeeting?: boolean
}

export const SalesDemo: FunctionComponent<Props> = ({ onSubmit, onOverride, bookedMeeting }) => {
  const { lead } = useContext(LeadContext)

  const isTablet = useRyuBreakpoints({ minWidth: 'tablet' })

  useEffect(() => {
    trackEvent('Contact Sales Page Viewed', {
      email: lead?.company_email ?? '',
      ...pageProperties(),
      experiments: lead?.experiments ?? [],
    })
    trackEvent('SS CS DR VE Total Page Viewed', {
      email: lead?.company_email ?? '',
      ...pageProperties(),
    })
  }, [lead?.company_email, lead?.experiments])

  const initialValues: SalesDemoForm = useMemo(
    () => ({
      first_name: lead?.first_name ?? '',
      last_name: lead?.last_name ?? '',
      company: lead?.company_name ?? '',
      interested_in_products: [],
    }),
    [lead]
  )

  if (bookedMeeting) {
    onOverride()
  }

  const isTreatment = isTablet && isWithinESTBusinessHours()

  if (isTreatment) {
    return (
      <SalesDemoTreatment
        initialValues={initialValues}
        lead={lead}
        onOverride={onOverride}
        onSubmit={onSubmit}
      />
    )
  }

  return (
    <SalesDemoControl
      initialValues={initialValues}
      lead={lead}
      onOverride={onOverride}
      onSubmit={onSubmit}
    />
  )
}
