import { useCallback, useMemo, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import {
  type KenPartialSearchParamsValue,
  type KenSearchParamsSchema,
  type KenSearchParamsSchemaProp,
  type KenSearchParamsSchemaPropNested,
  type KenSearchParamsSetValueOptions,
  type KenSearchParamsValue,
  type UseKenSearchParamsHook,
  type UseKenSearchParamsOptions,
} from './types'

export function useKenSearchParams<TSchema extends KenSearchParamsSchema>(
  options: UseKenSearchParamsOptions<TSchema>
): UseKenSearchParamsHook<TSchema> {
  const { search } = useLocation()
  const { push, replace } = useHistory()

  const originalSchema = useRef(options.schema)
  const originalDefaultValues = useRef(options.defaultValues)

  const currentSearchParams = useMemo(() => new URLSearchParams(search), [search])

  const values = useMemo<KenSearchParamsValue<TSchema>>(
    () =>
      readValuesFromSearchParams(
        originalSchema.current,
        currentSearchParams,
        originalDefaultValues.current
      ),
    [currentSearchParams]
  )

  const setValues = useCallback(
    (
      newValues: KenPartialSearchParamsValue<TSchema>,
      options: KenSearchParamsSetValueOptions = {
        replace: false,
      }
    ) => {
      const newSearchParams = new URLSearchParams(search)

      writeValuesToSearchParams(originalSchema.current, newValues, newSearchParams)

      const historyFunction = options.replace ? replace : push
      historyFunction({ search: newSearchParams.toString() })
    },
    [push, replace, search]
  )

  return [values, setValues]
}

function readValuesFromSearchParams<TSchema extends KenSearchParamsSchema>(
  schema: TSchema,
  searchParams: URLSearchParams,
  defaultValues: KenPartialSearchParamsValue<TSchema> | undefined,
  parentKey?: string
): KenSearchParamsValue<TSchema> {
  const schemaKeyValuePair = Array.from(Object.entries(schema))
  const params: Record<string, unknown> = {}

  for (const [key, schemaProp] of schemaKeyValuePair) {
    const keyWithParent = parentKey ? `${parentKey}.${key}` : key
    const keyCasted = key as keyof KenPartialSearchParamsValue<TSchema>

    if (typeof schemaProp === 'string') {
      const decodedValue = decodeValue(searchParams.get(keyWithParent) ?? '', schemaProp)

      params[key] = decodedValue ?? defaultValues?.[keyCasted]
    } else if (typeof schemaProp.decode === 'function') {
      params[key] = schemaProp.decode(searchParams.get(keyWithParent) ?? '')
    } else {
      params[key] = readValuesFromSearchParams(
        schemaProp as KenSearchParamsSchemaPropNested,
        searchParams,
        defaultValues?.[keyCasted] as never,
        keyWithParent
      )
    }
  }

  return params as KenSearchParamsValue<TSchema>
}

function writeValuesToSearchParams<TSchema extends KenSearchParamsSchema>(
  schema: TSchema,
  values: KenPartialSearchParamsValue<TSchema>,
  searchParams: URLSearchParams,
  parentKey?: string
): void {
  for (const [key, value] of Object.entries(values as KenSearchParamsValue<TSchema>)) {
    const keyWithParent = parentKey ? `${parentKey}.${key}` : key
    const keyCasted = key as keyof KenPartialSearchParamsValue<TSchema>
    const schemaProp = schema[key]

    if (typeof schemaProp === 'string') {
      const encodedValue = encodeValue(value, schemaProp)
      setOrDeleteValue(keyWithParent, encodedValue)
    } else if (typeof schemaProp.encode === 'function') {
      const encodedValue = schemaProp.encode(value)
      setOrDeleteValue(keyWithParent, encodedValue)
    } else {
      writeValuesToSearchParams(
        schemaProp as KenSearchParamsSchemaPropNested,
        values[keyCasted] as never,
        searchParams,
        key
      )
    }
  }

  function setOrDeleteValue(key: string, value: string | undefined) {
    if (value) {
      searchParams.set(key, value)
    } else {
      searchParams.delete(key)
    }
  }
}

function decodeValue<TPropType extends KenSearchParamsSchemaProp>(value: string, type: TPropType) {
  switch (type) {
    case 'string':
      return value.length ? value : undefined

    case 'boolean':
      if (value.toLowerCase() === 'true') {
        return true
      }

      if (value.toLowerCase() === 'false') {
        return false
      }

      return undefined

    case 'number': {
      const number = Number(value)
      return value.length && !isNaN(number) ? number : undefined
    }

    case 'date': {
      const date = new Date(value)

      return value.length && !isNaN(date.getTime()) ? date : undefined
    }

    case 'string[]': {
      const values = value.split(',').filter((item) => item.length > 0)

      return values.length ? values : undefined
    }

    case 'number[]': {
      const values = value
        .split(',')
        .filter((item) => item.length)
        .map(Number)
        .filter((item) => !isNaN(item))

      return values.length ? values : undefined
    }
  }
}

function encodeValue<TPropType extends KenSearchParamsSchemaProp>(
  value: unknown,
  type: TPropType
): string | undefined {
  if (type === 'string' && typeof value === 'string') {
    return value
  }

  if (type === 'boolean' && (value === true || value === false)) {
    return value.toString()
  }

  if (type === 'number' && typeof value === 'number') {
    return value.toString()
  }

  if (type === 'date' && value instanceof Date) {
    return value.toISOString()
  }

  if (type === 'string[]' && Array.isArray(value)) {
    const encodedValue = value.filter((item) => typeof item === 'string').join(',')
    return encodedValue.length ? encodedValue : undefined
  }

  if (type === 'number[]' && Array.isArray(value)) {
    const encodedValue = value.filter((item) => typeof item === 'number').join(',')
    return encodedValue.length ? encodedValue : undefined
  }

  return undefined
}
