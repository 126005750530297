export const PandoraTrackingPixel = ({ pandoraStage }: { pandoraStage: string }) => {
  if (!window.analytics?.user) {
    return null
  }

  return (
    <img
      src={`https://data.adxcel-ec2.com/pixel/?ad_log=referer&action=${pandoraStage}&content_id=${
        import.meta.env.NODE_ENV === 'development' ? 'dev' : window.analytics.user().anonymousId()
      }&pixid=9e24bf4a-bfbf-482b-ba25-2971397143e7`}
      width='0'
      height='0'
      alt=''
    />
  )
}

export const redditTrackingPixelForMQLs = (
  <script>{`
!function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);rdt('init','t2_fmjuhuib');rdt('track', 'Lead');
`}</script>
)
