import { type StripePayload } from '~/src/hooks/useStripeAppPayload'
import { unauthedApi } from '~/src/api'
import { trackEvent } from '~/src/utils/analytics/trackEvent'

type PostSignUpResponse = { token: string; user: any }

export async function postSignUp({
  user,
  first_name,
  last_name,
  email,
  cookies,
  stripePayload,
  salesLeadId,
}: {
  user: any
  first_name: string
  last_name: string
  email: string
  cookies: Record<string, any>
  stripePayload: StripePayload
  salesLeadId: string | undefined
}): Promise<void> {
  const login_method = 'RAMP_STANDARD'

  const { user: data } = await unauthedApi
    .post('post-sign-up', {
      json: {
        first_name,
        last_name,
        id_token: await user.getIdToken(),
        redeemed_code: cookies.rc ?? cookies.initial_rc,
        partner_promo_code: cookies.partner_promo ?? cookies.initial_partner_promo ?? cookies.promo,
        referral_uuid: cookies.referral_uuid ?? cookies.initial_referral_uuid,
        referral_source: cookies.utm_source ?? cookies.initial_utm_source,
        referral_location: cookies.referral_location ?? cookies.initial_referral_location,
        utm_campaign_from_user_sign_up:
          (cookies.utm_campaign && String(cookies.utm_campaign)) ??
          (cookies.initial_utm_campaign && String(cookies.initial_utm_campaign)), // we want utm_campaign from link used on sign up and there's small chance initial_utm_campaign already existed
        sales_lead_id: salesLeadId,
      },
      headers: {
        'stripe-user-id': stripePayload.userId,
        'stripe-account-id': stripePayload.accountId,
        'stripe-signature': stripePayload.stripeSignature,
      },
    })
    .json<PostSignUpResponse>()

  const userUTMs = {
    initial_utm_campaign: cookies.initial_utm_campaign ?? 'Inbound - Risk Application',
    initial_utm_campaign_at: cookies.initial_utm_campaign_at?.replace('%20', ' '),
    initial_utm_source: cookies.initial_utm_source,
    initial_utm_medium: cookies.initial_utm_medium,
    initial_utm_term: cookies.initial_utm_term,
    initial_utm_content: cookies.initial_utm_content,
    initial_promo: cookies.initial_partner_promo ?? cookies.initial_promo,
    initial_referral_code: cookies.initial_rc,
    initial_utm_campaign_id: cookies.initial_utm_campaign_id,
    initial_utm_ad_group: cookies.initial_utm_ad_group,
    initial_utm_ad_group_id: cookies.initial_utm_ad_group_id,
    initial_utm_ad: cookies.initial_utm_ad,
    initial_utm_ad_id: cookies.initial_utm_ad_id,
    initial_utm_placement: cookies.initial_utm_placement,
    initial_utm_cta_offer_text: cookies.initial_utm_cta_offer_text,
    utm_campaign: cookies.utm_campaign,
    utm_campaign_at: cookies.utm_campaign_at?.replace('%20', ' '),
    utm_source: cookies.utm_source,
    utm_medium: cookies.utm_medium,
    utm_term: cookies.utm_term,
    utm_content: cookies.utm_content,
    promo: cookies.partner_promo ?? cookies.promo,
    referral_code: cookies.rc,
    utm_campaign_id: cookies.utm_campaign_id,
    utm_ad_group: cookies.utm_ad_group,
    utm_ad_group_id: cookies.utm_ad_group_id,
    utm_ad: cookies.utm_ad,
    utm_ad_id: cookies.utm_ad_id,
    utm_placement: cookies.utm_placement,
    utm_cta_offer_text: cookies.utm_cta_offer_text,
  }

  // At this point the user is not fully authenticated, and only will be once they've verified their email.
  // Force refresh
  await user.getIdToken(true)

  window.analytics.identify(data.id, {
    role: 'BUSINESS_OWNER',
    email,
    first_name,
    last_name,
    login_method,
  })

  trackEvent('User Signed Up', {
    role: 'BUSINESS_OWNER',
    email,
    first_name,
    last_name,
    login_method,
    ...userUTMs,
  })
}
