import { RyuBreak, RyuFlex, RyuInputText, RyuPad } from '@ramp/ryu'
import { stringify } from 'query-string'
import { useState } from 'react'
import { Link } from 'react-router-dom'
const routes = [
  '/qualification',
  '/explore-product',
  '/explore-product-xp',
  '/explore-travel',
  '/explore-intelligence',
  '/verify-email',
  '/sign-up',
  '/see-a-demo/evaluate',
  '/explore-accounting-automation-gate',
  '/contact-sales',
]

export function DevIndex() {
  const [email, setEmail] = useState(`rcox+${crypto.randomUUID()}@ramp.com`)

  const queryParams = stringify({
    email,
  })

  return (
    <RyuPad size='l'>
      <RyuInputText value={email} label='email' onChange={setEmail} />
      <RyuBreak size='l' />
      <RyuFlex direction='column' alignItems='flexStart' gapSize='xs'>
        {routes.map((route) => (
          <Link key={route} to={{ pathname: route, search: queryParams, state: { email } }}>
            {route}
          </Link>
        ))}
      </RyuFlex>
    </RyuPad>
  )
}
