import { email as getEmailErrorString } from 'redux-form-validators'

export const BUSINESS_EMAIL_ERROR = 'Please enter a business email address.'
const BLOCKED_EMAIL_ERROR = 'This email address cannot be used with Ramp.'

// Keep in sync with suma/apps/utils/__init__.py on the backend
const personalEmailDomains = [
  '@gmail.com',
  '@outlook.com',
  '@yahoo.com',
  '@icloud.com',
  '@hotmail.com',
  '@aol.com',
  '@msn.com',
  '@me.com',
  '@live.com',
  '@att.net',
  '@comcast.net',
  '@gmx.com',
  '@googlemail.com',
  '@hotmail.co.uk',
  '@yahoo.co.uk',
  '@mail.com',
  '@rocketmail.com',
  '@protonmail.com',
  '@zoho.com',
  '@yandex',
  '@safe-mail.net',
  '@lavabit.com',
  '@currently.com',
]

// Blocked domains are rejected in all validators (even when personal domains are allowed)
const blockedEmailDomains = [
  'mailinator.com',
  'guerrillamail.biz',
  'guerrillamail.com',
  'guerrillamail.net',
  'guerrillamail.org',
  'guerrillamailblock.com',
  'sharklasers.com',
  'grr.la',
  'spam4.me',
  'yopmail.com',

  'bugfoo.com',
  'eurokool.com',
  'internetkeno.com',
  'kmbstock.com',
  'donebyngle.com',
  'exelica.com',
  'valanides.com',
  'vaband.com',
  'actapy.com',
  'aaorsi.com',
  'aramask.com',
  'bodeem.com',
  'byorby.com',
  'gufum.com',
  'kzccv.com',
  'camplvad.com',
  'wuuvo.com',
  'lrmumbaiwz.com',
  'miramail.my.id',
  'mvpve.com',
  'fullangle.org',
  'mymaily.lol',
  'pinefejy@socam.me',
  'elisejoanllc.com',
  'email-68.com',
  'socam.me',
  'kusrc.com',
  'suddenlink.net',
  'fexbox.org',
  'labworld.org',
  'finews.biz',
  'afia.pro',
  'bbitq.com',
  'tutuapp.bid',
  'lyft.live',
  'roani.top',
  'fugmi.com',
  'tozya.com',
  'mailto.plus',
  'munik.edu.pl',
]

export function isValidEmail(emailAddress?: string | null): boolean {
  if (!emailAddress) {
    return false
  }

  const blockedEmail = blockedEmailDomains.some((domain) => emailAddress.includes(domain))
  if (blockedEmail) {
    return false
  }

  return getEmailErrorString()(emailAddress) === undefined
}

function isPersonalEmail(emailAddress: string): boolean {
  return personalEmailDomains.some((domain) => emailAddress.includes(domain))
}

export function getPersonalEmailError(emailAddress: string): string | undefined {
  if (isPersonalEmail(emailAddress)) {
    return BUSINESS_EMAIL_ERROR
  }

  return undefined
}

export function getEmailDomain(email: string): string {
  return email.split('@')[1]
}

export function isValidEmailMessage(emailAddress?: string | null): string | undefined {
  if (!emailAddress) {
    return 'Please enter an email address.'
  }

  if (getEmailErrorString()(emailAddress)) {
    return 'Invalid email address.'
  }

  const blockedEmail = blockedEmailDomains.some((domain) => emailAddress.includes(domain))
  if (blockedEmail) {
    return BLOCKED_EMAIL_ERROR
  }
}

export function isValidEmailNoPersonalMessage(emailAddress?: string | null): string | undefined {
  const invalidEmailMessage = isValidEmailMessage(emailAddress)

  if (!emailAddress || invalidEmailMessage) {
    return invalidEmailMessage
  }

  const personalEmail = personalEmailDomains.some((domain) => emailAddress.includes(domain))
  if (!personalEmail) {
    return
  }

  return BUSINESS_EMAIL_ERROR
}

type GetMailToLinkParams = {
  to: string
  cc?: string
  subject?: string
  body?: string
}

export function getMailToLink({
  to: consumerTo,
  cc: consumerCc = '',
  subject: consumerSubject = '',
  body: consumerBody = '',
}: GetMailToLinkParams) {
  const to = encodeURIComponent(consumerTo.trim())
  const cc = encodeURIComponent(consumerCc.trim())
  const subject = encodeURIComponent(consumerSubject.trim())
  const body = encodeURIComponent(consumerBody.trim())

  const prefix = `mailto:${to}`

  const query = []

  if (cc) {
    query.push(`cc=${cc}`)
  }

  if (subject) {
    query.push(`subject=${subject}`)
  }

  if (body) {
    query.push(`body=${body}`)
  }

  const queryString = query.join('&')

  if (queryString) {
    return [prefix, queryString].join('?')
  }

  return prefix
}
