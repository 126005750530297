import * as Sentry from '@sentry/browser'
import { Location } from 'history'
import LogRocket from 'logrocket'
import LogrocketFuzzySanitizer from 'logrocket-fuzzy-search-sanitizer'

import { history } from '~/src/utils/history'
import { isDevelopment, isProduction } from '~/src/utils/env'

const ignoredPaths = [
  '/',
  '/about-us',
  '/capabilities',
  '/cardholder-agreement',
  '/careers',
  '/developer-agreement',
  '/platform-agreement',
  '/pricing',
  '/privacy-policy',
  '/prohibited-activities',
  '/reimbursements-agreement',
  '/savings',
  '/sign-in',
  '/sign-up',
]

export function init() {
  if (isDevelopment) {
    return
  }

  Sentry.init({
    dsn: import.meta.env.REACT_APP_SENTRY_DSN,
    environment: import.meta.env.REACT_APP_ENV,
    release: import.meta.env.REACT_APP_SENTRY_RELEASE_TAG,
  })

  window.analytics = window.analytics || []

  window.addEventListener('unhandledrejection', (e) => {
    Sentry.captureException(e.reason)
  })

  registerLogRocket()
}

function registerLogRocket() {
  if (registerLogRocketForLocation(history.location)) {
    return
  }

  const unregisterListener = history.listen((nextLocation) => {
    if (registerLogRocketForLocation(nextLocation)) {
      unregisterListener()
    }
  })
}

function registerLogRocketForLocation(location: Location): boolean {
  if (ignoredPaths.includes(location.pathname)) {
    return false
  }

  const { analytics } = window
  const sanitizer = LogrocketFuzzySanitizer.setup(['password', 'ssn_last_4', 'account_number'])

  const logRocketId = isProduction ? '7vojs5/ramp' : '7vojs5/ramp-qa'

  LogRocket.init(logRocketId, {
    release: import.meta.env.REACT_APP_SENTRY_RELEASE_TAG,
    // @ts-ignore discrepancy is TS noise, also the sanitizer is an official logrocket library
    network: sanitizer,
  })

  LogRocket.getSessionURL((sessionURL) => {
    Sentry.configureScope((scope) => {
      scope.setExtra('sessionURL', sessionURL)
    })
    analytics.track('LogRocket', {
      sessionURL,
    })
  })

  if (analytics.track) {
    const oldTrack = analytics.track.bind(analytics)
    analytics.track = (eventName: string, ...args: any[]) => {
      LogRocket.track(eventName)
      oldTrack(eventName, ...args)
    }
  }

  return true
}
