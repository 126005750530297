import { forwardRef, useState } from 'react'

import { RyuInputText, type RyuInputTextProps } from '@ramp/ryu'

type KenInputPasswordProps = Omit<
  RyuInputTextProps,
  'type' | 'iconSide' | 'iconType' | 'iconOnClick'
> & {
  revealedIconTooltip?: string
  obscuredIconTooltip?: string
}

export const KenInputPassword = forwardRef<HTMLInputElement, KenInputPasswordProps>((props, ref) => {
  const [showingPassword, setShowingPassword] = useState(false)

  return (
    <RyuInputText
      ref={ref}
      {...props}
      type={showingPassword ? 'text' : 'password'}
      _action={{
        iconType: showingPassword ? 'eye-off' : 'eye',
        tooltipContent: getTooltipContent(),
        variant: 'lean',
        onClick: () => setShowingPassword((showingPassword) => !showingPassword),
      }}
    />
  )

  function getTooltipContent() {
    if (props.obscuredIconTooltip && props.revealedIconTooltip) {
      return showingPassword ? props.revealedIconTooltip : props.obscuredIconTooltip
    }

    return showingPassword ? 'Hide password' : 'Show password'
  }
})
