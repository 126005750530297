import { FunctionComponent } from 'react'

import { RyuBreak, RyuButton, RyuFlex, RyuLink, RyuText } from '@ramp/ryu'

import { RigidPageContent } from '~/src/components/RigidPageContent'
import { isProduction } from '~/src/utils/env'

import { PandoraTrackingPixel } from '../TrackingPixels'
import { StepHeader } from '../StepHeader'
import { useCookies } from 'react-cookie'
import { stringify } from 'query-string'

export const unqualifiedTrackingPixels = isProduction ? (
  <PandoraTrackingPixel pandoraStage='misc' />
) : null

export const Unqualified: FunctionComponent = () => {
  const [cookies] = useCookies()
  const queryParams = stringify({
    rc: cookies.rc ?? cookies.initial_rc ?? undefined,
    referral_location: cookies.referral_location ?? cookies.initial_referral_location ?? undefined,
    partner_promo: cookies.partner_promo ?? cookies.initial_partner_promo ?? cookies.promo,
  })

  return (
    <RigidPageContent>
      {unqualifiedTrackingPixels}

      <StepHeader>We might not be a good fit for your company, yet.</StepHeader>

      <RyuText align='center' color='hushed'>
        If you are a new company in the process of raising capital, or you’d like to provide additional
        documentation for our team to review, please&nbsp;
        <RyuLink href={`https://app.ramp.com/sign-up?${queryParams}`} color='primary'>
          start an application
        </RyuLink>
        .
      </RyuText>

      <RyuBreak size='m' />

      <RyuText align='center' color='hushed'>
        Otherwise, we’d be happy to stay in touch and re-evaluate at another time.
      </RyuText>

      <RyuBreak size='l' />
      <RyuFlex justifyContent='center'>
        <RyuButton onClick={() => window.location.replace('https://ramp.com')}>Back to Home</RyuButton>
      </RyuFlex>
    </RigidPageContent>
  )
}
