import { type FieldValidator } from 'final-form'
import { isNil } from 'lodash-es'
import { type ReactNode, isValidElement } from 'react'
import { type FieldMetaState } from 'react-final-form'
import {
  type FieldError,
  type FieldPath,
  type FieldPathValue,
  type FieldValues,
  type Validate,
} from 'react-hook-form'
import { combine } from 'redux-form-validators'
import { type SetNonNullable, type SetRequired } from 'type-fest'

import { isTruthy } from '~/src/utils/filters'

export const kenRequiredError = {
  message: 'Field is required.', // this should never get shown, but just in case it does
}

export function hasFieldError<TValue>(meta: FieldMetaState<TValue>): boolean {
  // always show submit errors
  if (meta.submitError) {
    return true
  }

  // if we haven't modified the field, don't show errors
  if (
    !meta.modified && // if we submitted the form and it failed, now we should show all our errors
    !meta.submitFailed
  ) {
    return false
  }

  return !!meta.error
}

export function formatError(error: any, requiredText?: string): string | ReactNode | null {
  if (!(error ?? false)) {
    return null
  }

  if (error === kenRequiredError) {
    return requiredText ?? null
  }

  if (isValidElement(error)) {
    return error
  }

  return String(error)
}

export function combineValidators<TValue>(
  validators: (FieldValidator<TValue> | undefined | false)[]
): FieldValidator<TValue> | undefined {
  const filtered = validators.filter(isTruthy)

  if (!filtered) {
    return undefined
  }

  return combine(...filtered)
}

export const kenRequired: FieldValidator<any> = (value) => {
  if (value === null || value === undefined) {
    return kenRequiredError
  }

  if (typeof value === 'string' && value.length === 0) {
    return kenRequiredError
  }

  if (Array.isArray(value) && value.length === 0) {
    return kenRequiredError
  }
}

/**
 * This function is used to narrow down nullish type definitions for form values.
 *
 * **Use case**
 *
 * Consider the following example:
 *
 * ```tsx
 * import { useForm } from 'react-hook-form'
 *
 * import { KenFieldMaskMoney, KenFieldSelect, KenForm, KenFormSubmitButton } from '~/src/components/ken'
 *
 * enum CardInterval {
 *   DAILY = 'DAILY',
 *   WEEKLY = 'WEEKLY',
 *   MONTHLY = 'MONTHLY',
 *   // Omitted remaining values for brevity…
 * }
 *
 * type FormValues = {
 *   amount: number | null
 *   interval: CardInterval | null
 * }
 *
 * function CardForm() {
 *   const form = useForm<FormValues>({
 *     defaultValues: {
 *       amount: null,
 *       interval: null,
 *     },
 *   })
 *
 *   return (
 *     <KenForm
 *       form={form}
 *       render={({ formProps }) => (
 *         <form {...formProps}>
 *           <KenFieldMaskMoney<FormValues> name='amount' required={true} />
 *
 *           <KenFieldSelect<FormValues, 'interval', CardInterval>
 *             name='interval'
 *             required={true}
 *             items={Object.values(CardInterval)}
 *             parseItem={(interval) => ({ label: interval })}
 *           />
 *
 *           <KenFormSubmitButton />
 *         </form>
 *       )}
 *       onSubmit={(formValues) => {
 *         // `formValues` is of type `FormValues`.
 *       }}
 *     />
 *   )
 * }
 * ```
 *
 * Assuming `<KenForm>`, `<KenFieldMaskMoney required={true}>`, and `<KenFieldSelect required={true}>`
 * are functioning properly, there shouldn't be a case where `formValues.amount` and `formValues.
 * interval` are `null`. `assertNonNullableValues` provides 2 benefits:
 * 1. It adds a runtime check to ensure that the specified values aren't `null` or `undefined`.
 * 2. It narrows down the type definition of `FormValues`.
 *
 * ```tsx
 * onSubmit={(formValues) => {
 *   assertNonNullableValues(formValues, ['amount', 'interval'])
 *
 *   // (property) FormValues.amount: number
 *   formValues.amount
 *
 *   // (property) FormValues.interval: CardInterval
 *   formValues.interval
 * }}
 * ```
 */
export function assertNonNullableValues<
  TFormValues extends Record<string, any>,
  TKey extends keyof TFormValues,
>(
  formValues: TFormValues,
  keys: TKey[]
): asserts formValues is TFormValues &
  // `SetNonNullable` doesn't set optional properties to required, so we also have to use `SetRequired`.
  SetRequired<SetNonNullable<TFormValues, (typeof keys)[number]>, (typeof keys)[number]> {
  for (const key of keys) {
    if (isNil(formValues[key])) {
      throw new Error(
        `assertNonNullableValues: The key "${String(key)}" is either \`null\` or \`undefined\`.`
      )
    }
  }
}

export type KenFieldGetCaption = ({ error }: { error: FieldError | undefined }) => ReactNode | undefined

/**
 * Customize the caption for a field.
 *
 * `react-hook-form` only allows for strings as error messages.
 *
 * This allows for rendering error messages that use JSX.
 *
 * @example
 * ```tsx
 * <KenFieldText
 *   rules={{
 *     validate: {
 *       custom: (value) => value.trim() || 'This will be rendered with a custom error message.',
 *     },
 *   }}
 *   getCaption={({ error }) => {
 *     if (error?.type === 'custom') {
 *       return <CustomErrorMessage />
 *     }
 *
 *     return 'This is the default caption.'
 *   }}
 * />
 * ```
 */
export function getKenFieldCaption({
  error,
  caption,
  getCaption,
}: {
  error: FieldError | undefined
  caption: ReactNode | undefined
  getCaption: KenFieldGetCaption | undefined
}) {
  if (getCaption) {
    const result = getCaption({ error })

    if (result) {
      return result
    }
  }

  if (error?.message) {
    return error.message
  }

  return caption
}

export function addPristineValidator<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  pristine,
  label,
  originalValidate,
}: {
  pristine: boolean
  label: string
  originalValidate?:
    | Validate<FieldPathValue<TFieldValues, TFieldName>, TFieldValues>
    | Record<string, Validate<FieldPathValue<TFieldValues, TFieldName>, TFieldValues>>
}): Record<string, Validate<FieldPathValue<TFieldValues, TFieldName>, TFieldValues>> {
  return {
    pristine: (value, formValues) => {
      if (value === null && !pristine) {
        return label
      }

      if (typeof originalValidate === 'function') {
        return originalValidate(value, formValues)
      }
    },
    ...(typeof originalValidate === 'object' && originalValidate),
  }
}
