import { Fragment, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { RyuBreak, RyuLink, RyuText } from '@ramp/ryu'

import { RigidPage } from '~/src/components/RigidPage'
import { RigidPageContent } from '~/src/components/RigidPageContent'
import { UsernameSignUp } from '~/src/components/UsernameSignUp'
import { GoogleTagManager } from '~/src/scripts/gtm'
import { isProduction } from '~/src/utils/env'
import { trackEvent } from '~/src/utils/analytics'
import { pageProperties } from '~/src/utils/analytics/pageEnrichment'

export function DemoRequested() {
  const location = useLocation()
  const leadEmail = new URLSearchParams(location.search).get('email') ?? undefined
  useEffect(() => {
    trackEvent('Demo Requested Page Viewed', { email: leadEmail ?? '', ...pageProperties() })
    trackEvent('SS CS DR VE Total Page Viewed', { email: leadEmail ?? '', ...pageProperties() })
  }, [leadEmail])

  return (
    <Fragment>
      {isProduction && <GoogleTagManager />}
      <RigidPage>
        <RigidPageContent>
          <RyuText.h size='xl' align='center'>
            We’re looking forward to speaking with you soon.
          </RyuText.h>

          <RyuText.h size='m' color='hushed' align='center'>
            In the meantime, claim your account and start your application. It takes 5 minutes.
          </RyuText.h>

          <RyuBreak />

          <UsernameSignUp leadEmail={leadEmail} />

          <RyuBreak />

          <RyuText.div align='center' size='s'>
            Keep learning about Ramp on <RyuLink href='https://ramp.com'>our site</RyuLink>.
          </RyuText.div>

          <RyuBreak />
        </RigidPageContent>
      </RigidPage>
    </Fragment>
  )
}
