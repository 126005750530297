import { FunctionComponent } from 'react'
import { Route, Switch } from 'react-router-dom'
import ScoreLead from './ScoreLead'
import { LeadProvider } from '~/src/models/leads/LeadModel/provider'
import { evaluateLink } from './bookingRouteURLs'

const BookADemo: FunctionComponent = () => {
  return (
    <LeadProvider>
      <Switch>
        <Route path={evaluateLink} component={ScoreLead} />
      </Switch>
    </LeadProvider>
  )
}

export default BookADemo
