import { HTTPError } from 'ky'
import { Fragment, useState } from 'react'
import { useCookies } from 'react-cookie'
import { Helmet } from 'react-helmet-async'

import { RigidPage } from '~/src/components/RigidPage'
import { useCreateLead } from '~/src/hooks/leads'
import { trackEvent } from '~/src/utils/analytics'
import { logError } from '~/src/utils/errors'

import { EmailGate } from './EmailGate'
import { IntelligenceDemo } from './IntelligenceDemo'

export const ExploreIntelligence = () => {
  const createLead = useCreateLead()
  const [cookies, setCookie] = useCookies()
  const [emailGateOpen, setEmailGateOpen] = useState(true)

  const handleSubmit = async (email: string) => {
    setEmailGateOpen(false)
    setCookie('leadEmail', email, { path: '/' })
    trackEvent('Self Service Demo Started', { viewer_email: email })

    try {
      const discovery_method = cookies.initial_discovery_method ?? 'self_serve_product_demo'
      await createLead(email, { discovery_method }, { location: 'self-service product demo' })
    } catch (err) {
      if (err instanceof HTTPError) {
        const responseBody = await err.response.json()
        // Lead was already created
        if (responseBody.error_v2?.error_code !== 'LEAD_0001') {
          logError.high(err)
          return
        }
      }

      logError.high(err)
    }
  }

  return (
    <Fragment>
      <Helmet>
        <title>Ramp Demo - Welcome To Ramp | Ramp</title>
      </Helmet>
      <EmailGate open={emailGateOpen} onSubmit={handleSubmit} />
      <RigidPage>
        <IntelligenceDemo />
      </RigidPage>
    </Fragment>
  )
}
