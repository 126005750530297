import { Fragment, FunctionComponent } from 'react'

import {
  RyuBreak,
  RyuGrid,
  RyuText,
  RyuButton,
  RyuPad,
  RyuFlex,
  useRyuBreakpoints,
  type RyuListViewItemSingleConfig,
} from '@ramp/ryu'

import { styled } from 'styled-components'
import { ReviewTestimonial } from './ReviewTestimonial'
import rampLogo from '~/src/images/logos/logo-with-name.svg'
import { WistiaDemoVideo } from './WistiaDemoVideo'
import { useForm } from 'react-hook-form'
import { KenForm } from '~/src/components/ken/KenForm/KenForm'
import { KenFieldText } from '~/src/components/ken/KenForm/KenFieldText'
import { KenFieldSelect } from '~/src/components/ken/KenForm/KenFieldSelect'
import { KenFieldRadioGroup } from '~/src/components/ken/KenForm/KenFieldRadioGroup'
import { DropdownChoices, ContactSalesFormValues } from './types'
import { useForceTheme } from '~/src/utils/useForceTheme'

const companySizeMap: DropdownChoices[] = [
  {
    label: '1-10 Employees',
    value: '1-10',
  },
  {
    label: '11-50 Employees',
    value: '11-50',
  },
  {
    label: '51-100 Employees',
    value: '51-100',
  },
  {
    label: '101-250 Employees',
    value: '101-250',
  },
  {
    label: '251+ Employees',
    value: '251+',
  },
]

const monthlySpendMap: DropdownChoices[] = [
  {
    label: 'Under $10,000/mo',
    value: '<10k',
  },
  {
    label: '$10,000 - $50,000/mo',
    value: '10k-50k',
  },
  {
    label: '$50,000 - $250,000/mo',
    value: '50k-250k',
  },
  {
    label: 'Over $250,000/mo',
    value: '250k+',
  },
]

const Flex = styled.div`
  display: flex;
  flex-direction: column;
`

const ContentMinWidthContainer = styled.div`
  min-width: 480px;
  @media (max-width: 767px) {
    min-width: 100%;
  }
`
export type Props = {
  companyLogoUrl?: string
  has25k?: boolean | null
  onSubmit: (data: ContactSalesFormValues) => void
}

export const ContactSalesForm: FunctionComponent<Props> = ({ has25k, onSubmit }) => {
  const initialValues = {
    first_name: '',
    last_name: '',
    company_name: '',
    email: '',
    company_size: null,
    monthly_spend: null,
    has_over_25k: null,
  }
  const form = useForm<ContactSalesFormValues>({ defaultValues: initialValues })
  const isDesktop = useRyuBreakpoints({ minWidth: 'desktop' })

  const utmCampaign = new URLSearchParams(window.location.search).get('utm_campaign')

  const isDirectMailCampaign =
    !!utmCampaign?.startsWith('dm_notecard_102024') || !!utmCampaign?.startsWith('dm_formattest_102024')

  useForceTheme(isDirectMailCampaign ? 'ryu-light' : 'system')

  return (
    <ContactSalesLayout>
      <RyuFlex gapSize='l' alignItems='stretch' justifyContent='spaceBetween'>
        <ContentMinWidthContainer>
          <RyuPad sizeTop='xl' sizeBottom='xxl'>
            <img src={rampLogo} height={26} alt='Company Logo' />
            <div>
              <RyuText.h2 align='left' size='l' paddingVertical='top'>
                {isDirectMailCampaign ? 'Learn what Ramp can do for you.' : 'See how Ramp works'}
              </RyuText.h2>
              <RyuBreak size='s' />
              <RyuText.p align='left' size='m' color='hushed'>
                Schedule a 30-minute product demo with an expert specialist.
              </RyuText.p>
              <RyuBreak size='m' />
              <KenForm
                form={form}
                render={({ formProps }) => (
                  <form {...formProps}>
                    <Flex>
                      <KenFieldText<ContactSalesFormValues>
                        name='email'
                        type='email'
                        label='Work email'
                        variant='outlined'
                        hasError={!!form.formState.errors.email}
                        rules={{ required: true }}
                      />
                      <RyuBreak size='xs' />
                      <RyuGrid columns={2} responsiveColumns={1} gapSize='s'>
                        <KenFieldText<ContactSalesFormValues>
                          name='first_name'
                          label='First name'
                          variant='outlined'
                          hasError={!!form.formState.errors.first_name}
                          rules={{ required: true }}
                        />
                        <KenFieldText<ContactSalesFormValues>
                          name='last_name'
                          label='Last name'
                          variant='outlined'
                          hasError={!!form.formState.errors.last_name}
                          rules={{ required: true }}
                        />
                      </RyuGrid>

                      <RyuBreak size='xs' />

                      <KenFieldText<ContactSalesFormValues>
                        name='company_name'
                        label='Company name'
                        variant='outlined'
                        hasError={!!form.formState.errors.company_name}
                        rules={{ required: true }}
                      />

                      <RyuBreak size='xs' />

                      <KenFieldSelect<ContactSalesFormValues, 'company_size', DropdownChoices>
                        name='company_size'
                        label='Company size'
                        items={companySizeMap}
                        parseItem={parseCompanySizeItem}
                        variant='outlined'
                        hasError={!!form.formState.errors.company_size}
                        hideSearch={true}
                        rules={{ required: true }}
                      />

                      <RyuBreak size='xs' />

                      <KenFieldSelect<ContactSalesFormValues, 'monthly_spend', DropdownChoices>
                        name='monthly_spend'
                        label='Estimated monthly spend'
                        items={monthlySpendMap}
                        parseItem={parseMonthlySpendItem}
                        variant='outlined'
                        hasError={!!form.formState.errors.monthly_spend}
                        hideSearch={true}
                        rules={{ required: true }}
                      />

                      <RyuBreak size='l' />

                      <RyuText.p size='xs' color='hushed'>
                        My business has $25,000 in cash across all accounts
                      </RyuText.p>

                      <KenFieldRadioGroup<ContactSalesFormValues, 'has_over_25k'>
                        name='has_over_25k'
                        direction='row'
                        items={[true, false]}
                        rules={{ required: true }}
                        parseItem={(value) => ({
                          iconSide: 'right',
                          value,
                          label: value ? 'Yes' : 'No',
                        })}
                      />

                      <RyuBreak size='l' />

                      <RyuButton
                        variant='prime'
                        _textAlign='center'
                        color='primary'
                        type='submit'
                        fullWidth={true}
                      >
                        Book a demo
                      </RyuButton>
                    </Flex>
                  </form>
                )}
                onSubmit={onSubmit}
              />
              {has25k === false && (
                <Fragment>
                  <RyuBreak size='m' />
                  <RyuText.p size='s' color='destructive'>
                    Ramp requires $25k GLA. Read more about our requirements{' '}
                    <a href='https://support.ramp.com/hc/en-us/articles/7050688872851-Qualifying-and-Applying-to-Ramp'>
                      here
                    </a>
                    .
                  </RyuText.p>
                </Fragment>
              )}
              <RyuBreak size='xl' />
              <ReviewTestimonial />
            </div>
          </RyuPad>
        </ContentMinWidthContainer>

        {isDesktop && (
          <RyuFlex direction='column' alignItems='stretch' justifyContent='center' grow={1}>
            <WistiaDemoVideo />
          </RyuFlex>
        )}
      </RyuFlex>
    </ContactSalesLayout>
  )
}

function parseCompanySizeItem(company_size: DropdownChoices): RyuListViewItemSingleConfig {
  return {
    label: company_size.label,
    renderItem: () => company_size.label,
  }
}

function parseMonthlySpendItem(monthly_spend: DropdownChoices): RyuListViewItemSingleConfig {
  return {
    label: monthly_spend.label,
    renderItem: () => monthly_spend.label,
  }
}

const ContactSalesLayout = styled.div`
  max-width: 85em;
  margin: 0 auto;
  padding: 0 1.5rem;
`
