import { useCallback, useContext, useLayoutEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { type SubmitHandler, type UseFormReturn, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { useCreateFirebaseUser } from '~/src/hooks/useCreateFirebaseUser'
import { useAttributionCookies } from '~/src/hooks/useAttributionCookies'
import { useStripeAppPayload } from '~/src/hooks/useStripeAppPayload'
import { useSalesLeadData } from '~/src/hooks/useSalesLeadData'
import { checkIdentityExists } from '~/src/utils/checkIdentityExists'
import { hasExistingBusiness } from '~/src/utils/hasExistingBusiness'
import { postSignUp } from '~/src/utils/postSignUp'
import {
  BUSINESS_EMAIL_ERROR,
  isValidEmailMessage,
  isValidEmailNoPersonalMessage,
} from '~/src/utils/emails'
import { logError, parseHttpResponseError } from '~/src/utils/errors'
import { trackEvent } from '~/src/utils/analytics'
import { HTTPError } from 'ky'
import { LeadContext, useUpdateLeadModel } from '~/src/models/leads/LeadModel'
import { PossibleInterestedProducts } from '~/src/components/UsernameSignUp'

export type SignUpFormFields = {
  id?: string
  first_name: string
  last_name: string
  email: string
  password: string
  interested_in_products: PossibleInterestedProducts[]
}

export type UseSignUpParams = {
  onLeadEmailMatchesExistingIdentity?: () => void
  leadEmail?: string
  /**
   * Needed for Xero requirement. We can find a better place if needed
   */
  leadFirstName?: string
  leadLastName?: string
}

export type UseSignUpResponse = {
  leadCheckForExistingIdentityIsLoading: boolean
  leadEmailMatchesExistingIdentity: boolean
  allowPersonalEmails: boolean
  form: UseFormReturn<SignUpFormFields>
  existingBusinessError: string | null
  onSubmit: SubmitHandler<SignUpFormFields>
  validateEmail: (params: { email?: string | null; allowPersonalEmails: boolean }) => string | undefined
}

export function useSignUp({
  onLeadEmailMatchesExistingIdentity,
  leadEmail,
  leadFirstName,
  leadLastName,
}: UseSignUpParams): UseSignUpResponse {
  const [isLoading, setIsLoading] = useState(false)
  const [existingBusinessError, setExistingBusinessError] = useState<string | null>(null)
  const [haveTrackedBefore, setHaveTrackedBefore] = useState(false)
  const [leadEmailMatchesExistingIdentity, setLeadEmailMatchesExistingIdentity] = useState(false)

  const history = useHistory()
  const [cookies] = useCookies()
  useAttributionCookies()
  const createFirebaseUser = useCreateFirebaseUser()

  const stripePayload = useStripeAppPayload()
  const salesLeadData = useSalesLeadData()

  const form = useForm<SignUpFormFields>({
    defaultValues: {
      first_name: salesLeadData?.first_name ?? leadFirstName ?? '',
      last_name: salesLeadData?.last_name ?? leadLastName ?? '',
      email: leadEmail ?? salesLeadData?.email ?? '',
      password: '',
      interested_in_products: [],
    },
    criteriaMode: 'all',
  })

  useLayoutEffect(
    () => {
      checkLeadEmailForExistingIdentity()

      async function checkLeadEmailForExistingIdentity() {
        if (!leadEmail) {
          return
        }

        try {
          setIsLoading(true)

          const identityExists = await checkIdentityExists(leadEmail)

          if (identityExists) {
            setLeadEmailMatchesExistingIdentity(true)

            onLeadEmailMatchesExistingIdentity?.()
          }
        } catch (error) {
          logError.high(error)
        } finally {
          setIsLoading(false)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const partnerPromo = cookies.partner_promo ?? cookies.initial_partner_promo ?? cookies.promo
  const allowPersonalEmails =
    cookies.allow_personal_email_signup ||
    partnerPromo === 'smartbiz' ||
    partnerPromo === 'live-oak-bank' ||
    partnerPromo === 'grasshopper-bank' ||
    partnerPromo === 'frb' ||
    partnerPromo === 'wells-fargo' ||
    partnerPromo === 'firstbase'

  const { lead, setLead } = useContext(LeadContext)
  const updateLead = useUpdateLeadModel()

  const onSubmit = useCallback<SubmitHandler<SignUpFormFields>>(
    async (data) => {
      const identityExists = await checkIdentityExists(data.email)

      if (identityExists) {
        window.location.replace(`/sign-in?email=${encodeURIComponent(data.email)}`)
        return
      }

      const { valid, nextError } = await hasExistingBusiness({
        email: data.email,
        existingBusinessError,
      })
      setExistingBusinessError(nextError)

      if (valid === false) {
        return
      }

      try {
        if (lead?.id) {
          const updateLeadModelPayload = {
            interested_in_bill_payments: data.interested_in_products.includes(
              'interested_in_bill_payments'
            ),
            interested_in_corporate_cards: data.interested_in_products.includes(
              'interested_in_corporate_cards'
            ),
            interested_in_procurement: data.interested_in_products.includes('interested_in_procurement'),
            interested_in_treasury: data.interested_in_products.includes('interested_in_treasury'),
          }

          try {
            const updatedLead = await updateLead(lead.id, updateLeadModelPayload)
            setLead((prevLeadModel) => {
              if (prevLeadModel) {
                return { ...prevLeadModel, ...updateLeadModelPayload, ...updatedLead }
              }

              return prevLeadModel
            })
          } catch (e) {}
        }

        const credentials = await createFirebaseUser({
          email: data.email,
          password: data.password,
        })

        try {
          await postSignUp({
            user: credentials.user,
            first_name: data.first_name,
            last_name: data.last_name,
            email: credentials.user.email!,
            cookies,
            stripePayload,
            salesLeadId: salesLeadData?.id,
          })
        } catch (error) {
          const { user } = credentials

          // /post-sign-up fails sometimes and it puts users in a broken state where they
          // have a Firebase user, but not a Ramp user. This ensures that when it fails,
          // we also delete the Firebase user.
          //
          // We handle two cases here, the 500 in case BE fails (timeouts, bugs, network issues),
          // and local request failures where browsers will throw a TypeError with various messages
          // like "load failed" or "failed to fetch".

          const handleGenericServerError = async () => {
            await user.delete()
            logError.high(new Error('Sign up new user firebase error'))
          }

          if (error instanceof TypeError && error.message?.toLowerCase().includes('failed')) {
            handleGenericServerError()
            throw error
          }

          if (error instanceof HTTPError) {
            if (error.response.status >= 500) {
              handleGenericServerError()
            } else if (error.response.status === 400) {
              logError.medium('account already exists')
            } else if (error.response.status === 404) {
              logError.medium('getPostSignUpToken NO_INVITE_FOUND')
            } else {
              logError.medium(new Error('getPostSignUpToken UNKNOWN_ERROR'))
            }
          }

          throw error
        }

        history.push('/verify-email', {
          email: credentials.user.email,
          sendOnLoad: true,
        })
      } catch (error) {
        const { errorMessage } = await parseHttpResponseError(error)

        if (errorMessage) {
          form.setError('email', { message: errorMessage })
        }
      }
    },
    [
      cookies,
      createFirebaseUser,
      existingBusinessError,
      form,
      history,
      salesLeadData,
      stripePayload,
      lead,
      updateLead,
      setLead,
    ]
  )

  const validateEmail = useCallback<UseSignUpResponse['validateEmail']>(
    ({ email, allowPersonalEmails }) => {
      const errorMessage = allowPersonalEmails
        ? isValidEmailMessage(email)
        : isValidEmailNoPersonalMessage(email)

      if (!email || errorMessage !== BUSINESS_EMAIL_ERROR) {
        return errorMessage
      }

      if (!haveTrackedBefore) {
        trackEvent('Personal Email Error Shown', {
          email,
          signup_method: 'RAMP_STANDARD',
        })
        setHaveTrackedBefore(true)
      }

      return `Please enter a valid business email (not ${email.split('@')[1]})`
    },
    [haveTrackedBefore]
  )

  return {
    leadCheckForExistingIdentityIsLoading: isLoading,
    leadEmailMatchesExistingIdentity,
    allowPersonalEmails,
    form,
    existingBusinessError,
    onSubmit,
    validateEmail,
  }
}
