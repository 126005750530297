import { isNil } from 'lodash-es'

/**
 * Returns `true` if the `item` is not `null` or `undefined`.
 */
export function isNotNullish<TItem>(item: TItem): item is NonNullable<TItem> {
  return !isNil(item)
}

export type FalsyValue = false | 0 | '' | null | undefined

export function isTruthy<TItem>(item: TItem | FalsyValue): item is TItem {
  return !!item
}

export function isNotEmpty<TItem>(item: TItem | FalsyValue): item is TItem {
  return isNotNullish(item) && (!Array.isArray(item) || item.length > 0)
}
