import { LeadModel } from './types'
import { useState, ReactNode, FunctionComponent } from 'react'
import { LeadContext } from './context'

type Props = {
  children: ReactNode
}

export const LeadProvider: FunctionComponent<Props> = ({ children }) => {
  const [lead, setLeadState] = useState<null | LeadModel>(null)
  const [isLeadHydrated, setIsLeadHydrated] = useState(false)

  /**
   * Fix for #_inc-09-06-large-scan-of-leads-endpoint-inc16244
   *
   * Helper function to set default values for the name fields when hydrating from backend
   *
   * TODO: Remove this, eventually frontend will not rely on these name fields from the backend
   */
  const setHydratedLead = (
    newLead: LeadModel | null | ((prevLead: LeadModel | null) => LeadModel | null)
  ) => {
    setLeadState((prevLead) => {
      const updatedLead = typeof newLead === 'function' ? newLead(prevLead) : newLead
      if (updatedLead) {
        return {
          ...updatedLead,
          employee_count: undefined,
          has_employee_count: !!updatedLead.employee_count,
          estimated_monthly_spend: undefined,
          has_estimated_monthly_spend: !!updatedLead.estimated_monthly_spend,
          bank_balance: undefined,
          has_bank_balance: !!updatedLead.bank_balance,
          company_name: undefined,
          has_company_name: !!updatedLead.company_name,
          first_name: undefined,
          has_first_name: !!updatedLead.first_name,
          last_name: undefined,
          has_last_name: !!updatedLead.last_name,
        }
      }

      return null
    })
  }

  return (
    <LeadContext.Provider
      value={{
        lead,
        setLead: setLeadState,
        setHydratedLead,
        isLeadHydrated,
        setIsLeadHydrated,
      }}
    >
      {children}
    </LeadContext.Provider>
  )
}
