import { RyuButton } from '@ramp/ryu'
import { FunctionComponent } from 'react'
import { useHistory } from 'react-router-dom'

type Props = {
  onClick: () => void
}
export const BackButton: FunctionComponent<Props> = ({ onClick }) => {
  const history = useHistory()

  return (
    <RyuButton
      size='s'
      onClick={() => {
        onClick()
        history.goBack()
      }}
    >
      Back
    </RyuButton>
  )
}
