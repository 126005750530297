import { Fragment, FunctionComponent } from 'react'

import { RyuBreak, RyuInputCheckbox } from '@ramp/ryu'

type Option = {
  label: string
  value: string
}

type Props = {
  onChange: (checked: boolean, value: string) => void
  options: Option[]
  selectedOptions: Set<string>
}

export const CheckboxPicker: FunctionComponent<Props> = ({ onChange, options, selectedOptions }) => {
  return (
    <Fragment>
      {options.map((option) => {
        return (
          <Fragment key={option.value}>
            <RyuInputCheckbox
              iconSide='left'
              // size='l'
              // paddingSizeHorizontal='xl'
              // paddingSizeVertical='m'
              label={option.label}
              checked={selectedOptions.has(option.value)}
              onChange={(nextChecked) => {
                onChange(nextChecked, option.value)
              }}
            />
            <RyuBreak />
          </Fragment>
        )
      })}
    </Fragment>
  )
}
