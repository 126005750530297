import { Fragment, useCallback } from 'react'
import { type Promisable } from 'type-fest'

import {
  type RyuActionProps,
  type RyuIconType,
  type RyuToastColor,
  type ShowToastProps,
  type ShowToastResult,
  useMounted,
  useRyuToast,
} from '@ramp/ryu'

import { URGENCY, logError, parseHttpResponseError } from './errors'
import { trackEvent } from './analytics'

export const DEFAULT_ERROR_MESSAGE = 'There was an error.'

// Error gracefully caught by KenApiRequestButton. Useful for showing a validation error on forms.
export class KenValidationError extends Error {}

export type WrapAPIRequestParams<TReturnValue = void, TParams extends any[] = []> = {
  showToastOnError?: boolean
  showContactEmailOnError?: boolean // TODO - deprecate to simplify interface and enforce consistency
  errorUrgency?: URGENCY // TODO - deprecate to simplify interface and enforce consistency
  defaultErrorMessage?: string // TODO - deprecate to simplify interface and enforce consistency
  handleRequestError?: (error: any) => any
  loadingToastProps?: Pick<ShowToastProps, 'title' | 'description'>
  errorToastProps?: Pick<ShowToastProps, 'timeout'>
  toastSuccessIcon?: RyuIconType
  toastSuccessMessage?: string
  toastSuccessVariant?: RyuToastColor
  toastSuccessActions?: RyuActionProps[]
  callbackFunction?: (...args: TParams) => Promisable<TReturnValue>
  onFinishCallbackFunction?: (...args: TParams) => void
  setIsLoading?: (nextIsLoading: boolean) => void
}

export function useWrappedAPIRequest<TReturnValue = void, TParam extends any[] = []>(
  args: WrapAPIRequestParams<TReturnValue, TParam>
) {
  const wrapAPIRequest = useWrapAPIRequest<TReturnValue, TParam>()

  return wrapAPIRequest(args)
}

export function useWrapAPIRequest<TReturnValue, TParam extends any[] = []>() {
  const isMounted = useMounted()
  const showToast = useRyuToast()

  return useCallback(
    ({
      showToastOnError = true,
      showContactEmailOnError = false,
      errorUrgency = URGENCY.HIGH,
      defaultErrorMessage = DEFAULT_ERROR_MESSAGE,
      handleRequestError,
      loadingToastProps,
      toastSuccessIcon,
      toastSuccessMessage,
      toastSuccessVariant = 'constructive',
      toastSuccessActions,
      callbackFunction,
      onFinishCallbackFunction,
      setIsLoading,
      errorToastProps = {},
    }: WrapAPIRequestParams<TReturnValue, TParam>) =>
      async (...args: TParam): Promise<TReturnValue | undefined> => {
        let toast: ShowToastResult | null = null

        setIsLoading?.(true)

        if (loadingToastProps) {
          updateToast({
            ...loadingToastProps,
            iconType: 'loading',
            timeout: false,
          })
        }

        try {
          const returnValue = await callbackFunction?.(...args)

          if (toastSuccessMessage) {
            updateToast({
              color: toastSuccessVariant,
              iconType: toastSuccessIcon,
              title: toastSuccessMessage,
              actions: toastSuccessActions,
            })
          }

          return returnValue
        } catch (error) {
          if (error instanceof KenValidationError) {
            const title = error.message

            if (!title) {
              return
            }

            updateToast({
              ...errorToastProps,
              color: 'destructive',
              title,
            })

            return
          }

          if (error instanceof TypeError && error.message.toLowerCase() === 'failed to fetch') {
            updateToast({
              ...errorToastProps,
              color: 'destructive',
              title: 'Network error. Please try again.',
            })

            return
          }

          handleRequestError?.(error)

          logError[errorUrgency](error)

          if (showToastOnError) {
            await showErrorToast(error)
          }
        } finally {
          onFinishCallbackFunction?.(...args)
          if (isMounted.current) {
            setIsLoading?.(false)
          }
        }

        async function showErrorToast(error: any) {
          const { endpoint, errorCode, errorMessage } = await parseHttpResponseError(error, {
            defaultErrorMessage,
          })

          const toastErrorMessage = showContactEmailOnError ? (
            <Fragment>{errorMessage}</Fragment>
          ) : (
            errorMessage
          )

          updateToast({
            ...errorToastProps,
            color: 'destructive',
            title: toastErrorMessage,
          })

          trackEvent('Error Toast Displayed from APIRequestButton', {
            endpoint,
            errorCode,
          })
        }

        function updateToast(props: ShowToastProps) {
          if (toast) {
            toast.setProps(props)
          } else {
            toast = showToast(props)
          }
        }
      },
    [showToast, isMounted]
  )
}
