import { LeadModel } from '~/src/models/leads'
import { trackEvent } from '~/src/utils/analytics/trackEvent'

export type ChilipiperEvent = {
  data: {
    action: 'no-free-slots' | 'availability-loaded'
  }
}

export function getChilipiperEventPayload({ lead, cookies }: { lead: LeadModel | null; cookies: any }) {
  return {
    fbp: cookies._fbp,
    fbc: cookies._fbc,
    first_name: lead?.first_name,
    last_name: lead?.last_name,
    company_name: lead?.company_name,
    company_email: lead?.company_email,
    employee_count: lead?.employee_count,
    monthly_spend: lead?.estimated_monthly_spend ?? null,
    bank_balance: lead?.bank_balance,
    experiment: lead?.experiment,
  }
}

export function receiveChiliPiperMessage({
  event,
  lead,
  cookies,
}: {
  event: ChilipiperEvent
  lead: LeadModel | null
  cookies: any
}) {
  const chilipiperEventPayload = getChilipiperEventPayload({ lead, cookies })

  if (event.data.action === 'no-free-slots') {
    trackEvent('Chilipiper Widget - No Free Slots', chilipiperEventPayload)
  } else if (event.data.action === 'availability-loaded') {
    trackEvent('Chilipiper Widget - Availability Loaded', chilipiperEventPayload)
  }
}
