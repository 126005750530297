import { useMemo } from 'react'

export type StripePayload = {
  userId?: string
  accountId?: string
  stripeSignature?: string
}

export function useStripeAppPayload(): StripePayload {
  return useMemo(() => {
    const params = new URLSearchParams(window.location.search)
    return {
      userId: params.get('user_id') ?? undefined,
      accountId: params.get('account_id') ?? undefined,
      stripeSignature: params.get('stripe_signature') ?? undefined,
    }
  }, [])
}
