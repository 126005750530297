import { type UserCredential } from 'firebase/auth'
import { useCallback } from 'react'

import { logError } from '~/src/utils/errors'
import { createUserWithEmailAndPassword } from '~/src/utils/integrations/firebase'

type EmailAndPassword = { email: string; password: string }

/**
 * A wrapper around Firebase's `createUserWithEmailAndPassword` that handles errors
 * @example redirects to the sign in page if email is already in use
 */
export function useCreateFirebaseUser(): (params: EmailAndPassword) => Promise<UserCredential> {
  return useCallback(async ({ email, password }: EmailAndPassword) => {
    try {
      const credentials = await createUserWithEmailAndPassword(email, password)
      return credentials
    } catch (error: any) {
      if (error.code === 'auth/email-already-in-use') {
        window.location.replace(`/sign-in?email=${encodeURIComponent(email)}`)
      }

      logError.veryHigh(error)

      throw error
    }
  }, [])
}
