import { useEffect, useState, useCallback } from 'react'

type ScriptAttributes = {
  key: string
  value: string
}

type ScriptParams = {
  attributes: ScriptAttributes[]
}

type ScriptStatus = 'idle' | 'loading' | 'ready' | 'error'

/**
 * Hook to dynamically load an external script.
 *
 * This is useful when interacting with a 3rd party library (Stripe, Google Analytics, etc),
 * and you'd prefer to load the script when needed rather then include it in the
 * document head for every page request.
 *
 * Source: https://usehooks.com/useScript/
 *
 * @param src - The url of the script
 * @param params - Attributes to set on the script
 * @param loadOnMount - Whether to load the script on mount or not
 *
 * @returns The status of the script ("loading", "ready", etc.) and a function to load the script
 */
type UseScriptOptions = {
  src: string
  params?: ScriptParams
  loadOnMount?: boolean
}

export const useScript = ({
  src,
  params = { attributes: [] },
  loadOnMount = true,
}: UseScriptOptions) => {
  const [status, setStatus] = useState<ScriptStatus>(() => (src && loadOnMount ? 'loading' : 'idle'))

  const loadScript = useCallback(() => {
    if (!src) {
      setStatus('idle')
      return
    }

    // Fetch existing script element by src
    // It may have been added by another instance of this hook
    let script: HTMLScriptElement | null = document.querySelector(`script[src="${src}"]`)

    if (!script) {
      // Create script
      script = document.createElement('script')
      script.src = src
      script.async = true
      script.setAttribute('data-status', 'loading')
      params.attributes.map(({ key, value }) => script?.setAttribute(key, value))
      // Add script to document body
      document.body.appendChild(script)

      // Store status in attribute on script
      // This can be read by other instances of this hook
      const setAttributeFromEvent = (event: Event) => {
        if (script) {
          script.setAttribute('data-status', event.type === 'load' ? 'ready' : 'error')
        }
      }

      script.addEventListener('load', setAttributeFromEvent)
      script.addEventListener('error', setAttributeFromEvent)
    } else {
      // Grab existing script status from attribute and set to state.
      setStatus((script.getAttribute('data-status') as ScriptStatus) ?? 'idle')
    }

    // Script event handler to update status in state
    // Note: Even if the script already exists we still need to add
    // event handlers to update the state for *this* hook instance.
    const setStateFromEvent = (event: Event) => {
      setStatus(event.type === 'load' ? 'ready' : 'error')
    }

    // Add event listeners
    script.addEventListener('load', setStateFromEvent)
    script.addEventListener('error', setStateFromEvent)

    // Remove event listeners on cleanup
    return () => {
      if (script) {
        script.removeEventListener('load', setStateFromEvent)
        script.removeEventListener('error', setStateFromEvent)
      }
    }
  }, [src, params.attributes])

  useEffect(() => {
    if (loadOnMount) {
      loadScript()
    }
  }, [loadOnMount, loadScript])

  return { status, loadScript }
}
