import moment from 'moment'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import CookiesUtil from '../utils/cookies'

export const DAYS_TO_EXPIRE = 90

/**
 * Hook that sets cookies based on the current URL search params.
 *
 * The cookies expire in 90 days.
 */
export const useAttributionCookies = () => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const date = moment().add(DAYS_TO_EXPIRE, 'days')
  const [areCookiesSet, setAreCookiesSet] = useState(false)

  useEffect(() => {
    const cookies = CookiesUtil.getAll()
    searchParams.forEach((value, key) => {
      if (cookies[`initial_${key}`]) {
        CookiesUtil.set(key, value, {
          expires: date.toDate(),
        })
      } else {
        CookiesUtil.set(`initial_${key}`, value, {
          expires: date.toDate(),
        })
      }
    })
    setAreCookiesSet(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return areCookiesSet
}
