import { Fragment, FunctionComponent } from 'react'

import { RyuBreak, RyuPane } from '@ramp/ryu'

type Props = {
  onChange: (value: any) => void
  options: any[]
}

export const RadioPicker: FunctionComponent<Props> = ({ onChange, options }) => {
  return (
    <Fragment>
      {options.map((option) => {
        return (
          <Fragment key={option.value}>
            <RyuPane
              data-test-id={`${option.label}-pane`}
              label={option.label}
              paddingSizeHorizontal='xl'
              paddingSizeVertical='l'
              onClick={() => onChange(option.value)}
            />
            <RyuBreak />
          </Fragment>
        )
      })}
    </Fragment>
  )
}
