// Our analytics provider segment stores a lot of user information in
// localStorage, which from a security analysis is a potential attack vector
// https://linear.app/tryramp/issue/PRSEC-59/insecure-html5-local-storage-repeat-finding

const items = ['ajs_group_id', 'ajs_group_properties', 'ajs_user_id', 'ajs_user_traits']

// this installs a plugin into analytics that runs our wiper after every identifying call

const hasLocalStorage = typeof Storage !== 'undefined'

export function installUserDataWiper() {
  window.analytics.ready(() => {
    window.analytics.register({
      type: 'after',
      isLoaded: () => true,
      load: () => Promise.resolve(),
      identify(ctx: unknown) {
        wipeStoredAnalyticsData()

        return ctx
      },
      page(ctx: unknown) {
        wipeStoredAnalyticsData()

        return ctx
      },
    })
  })

  // we also clear it when the window is unloaded
  window.addEventListener(
    'beforeunload',
    () => {
      if (!hasLocalStorage) {
        return
      }

      for (const item of items) {
        localStorage.removeItem(item)
      }
    },
    false
  )
}

function wipeStoredAnalyticsData() {
  if (!hasLocalStorage) {
    return
  }

  setTimeout(() => {
    for (const item of items) {
      localStorage.removeItem(item)
    }
  }, 2500) // the data can be stored very late, so wipe it very late
}
