import { RyuThemePreference, useRyuThemeState } from '@ramp/ryu'
import { useLayoutEffect } from 'react'

export function useForceTheme(theme: RyuThemePreference) {
  const [themeState, setTheme] = useRyuThemeState()
  useLayoutEffect(() => {
    const currentTheme = themeState
    setTheme(theme)
    return () => setTheme(currentTheme)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theme, setTheme])
}
