import { RyuImage, RyuFlex, RyuPad, RyuText, RyuBreak } from '@ramp/ryu'
import { useEffect, useRef, useState } from 'react'
import { styled } from 'styled-components'
import barrys from '~/src/images/testimonials/barrys.png'
import glossier from '~/src/images/testimonials/glossier.png'
import webflow from '~/src/images/testimonials/webflow.png'

import barrysLogo from '~/src/images/logos/barrys-logo.svg'
import glossierLogo from '~/src/images/logos/glossier-logo.svg'
import webflowLogo from '~/src/images/logos/webflow-logo.svg'

type TestimonialSlideProps = {
  quoteText: string
  quoteAuthorName: string
  stats: { title: string; description: string }[]
  imageUrl: string
  logoImageUrl: string
}

const testimonialSlides: TestimonialSlideProps[] = [
  {
    quoteText:
      '"It’s really important that we have a platform like Ramp that encourages us to keep evolving, it’s good for the team and it’s good for the business.”',
    quoteAuthorName: 'Roxane Cosnard des Closets, Senior Manager of Financial Systems',
    stats: [
      {
        title: '6 weeks',
        description: 'from discovery to onboarding',
      },
      {
        title: '5 hours',
        description: 'saved per week',
      },
    ],
    imageUrl: glossier,
    logoImageUrl: glossierLogo,
  },
  {
    quoteText:
      '"As we scale we need tools that are built to scale with us - we need to see expenses real time, we need to see duplicate spend. These types of insights are important to the health of our business."',
    quoteAuthorName: 'Steve Padis, EVP Finance & Strategy',
    stats: [
      {
        title: '5 days',
        description: 'saved monthly with automation',
      },
      {
        title: '$20k',
        description: 'savings through insights and card',
      },
    ],
    imageUrl: barrys,
    logoImageUrl: barrysLogo,
  },

  {
    quoteText:
      '“I’ve heard a lot of positive feedback from new team members about their experience onboarding with Ramp. They are invited to the platform and on that same day, they get a card and they’re able to start their work.”',
    quoteAuthorName: 'Sheila Ellis, Controller',
    stats: [
      {
        title: '75%',
        description: 'reduction in credit card reconciliation time',
      },
    ],
    imageUrl: webflow,
    logoImageUrl: webflowLogo,
  },
]

export function TestimonialCarouselSidebar() {
  const [index, setIndex] = useState(0)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
  }

  useEffect(() => {
    resetTimeout()
    timeoutRef.current = setTimeout(
      () => setIndex((prevIndex) => (prevIndex === testimonialSlides.length - 1 ? 0 : prevIndex + 1)),
      7000
    )
    return () => {
      resetTimeout()
    }
  }, [index])

  return (
    <TestimonialSidebar>
      <TestimonialSidebarSlider position={index}>
        <RyuFlex alignItems='flexStart'>
          {testimonialSlides.map((slide, idx) => (
            <TestimonialSlide key={idx} slide={slide} />
          ))}
        </RyuFlex>
      </TestimonialSidebarSlider>
    </TestimonialSidebar>
  )
}

function TestimonialSlide({ slide }: { slide: TestimonialSlideProps }) {
  return (
    <TestimonialSlideContainer>
      <TestimonialImageContainer>
        <TestimonialLogoImage>
          <RyuImage src={slide.logoImageUrl} />
        </TestimonialLogoImage>

        <TestimonialBackgroundImage>
          <RyuImage src={slide.imageUrl} />
        </TestimonialBackgroundImage>
      </TestimonialImageContainer>

      <RyuBreak size='xl' />

      <RyuFlex direction='column' alignItems='flexStart' justifyContent='spaceBetween'>
        <RyuPad sizeHorizontal='xl' sizeVertical='s'>
          <RyuText.div size='xl'>{slide.quoteText}</RyuText.div>

          <RyuBreak size='l' />

          <RyuText.div size='s' color='hushed'>
            {slide.quoteAuthorName}
          </RyuText.div>
        </RyuPad>

        <RyuBreak />

        <RyuPad size='xl'>
          <RyuFlex gapSize='xl'>
            {slide.stats.map((stat, idx) => (
              <div key={idx}>
                <RyuText.h3 size='l' paddingVertical='none'>
                  {stat.title}
                </RyuText.h3>

                <RyuBreak size='xs' />

                <RyuText.div size='s' color='hushed'>
                  {stat.description}
                </RyuText.div>
              </div>
            ))}
          </RyuFlex>
        </RyuPad>
      </RyuFlex>
    </TestimonialSlideContainer>
  )
}

const TestimonialSidebar = styled.div`
  min-height: 100vh;
  display: flex;
  flex: 1;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.ryuColors.lighterShade};
`

const TestimonialSidebarSlider = styled.div<{ position: number }>`
  transform: ${({ position }) => `translate3d(-${position * 100}%, 0, 0)`};
  transition: ease 1000ms;
`

const TestimonialLogoImage = styled.div`
  position: absolute;
  max-height: 10rem;
  top: 2rem;
  left: 2rem;
`

const TestimonialImageContainer = styled.div`
  position: relative;
`

const TestimonialBackgroundImage = styled.div`
  img {
    max-height: 30rem;
    width: 100%;
    object-fit: cover;
  }
`

const TestimonialSlideContainer = styled.div`
  width: 100%;
  height: 100%;
  width: 34vw;
`
