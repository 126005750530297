import { RyuText } from '@ramp/ryu'
import { FunctionComponent, ReactNode } from 'react'

interface Props {
  children: ReactNode
}

export const StepHeader: FunctionComponent<Props> = ({ children }) => {
  return <RyuText.h3 align='center'>{children}</RyuText.h3>
}
