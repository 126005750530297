import { type BaseSyntheticEvent, type ReactNode, type Ref, useImperativeHandle } from 'react'
import {
  type FieldValues,
  FormProvider,
  type SubmitErrorHandler,
  type SubmitHandler,
  type UseFormHandleSubmit,
  type UseFormReturn,
} from 'react-hook-form'

import { type RyuActionResolvedProps } from '@ramp/ryu'

import { type WrapAPIRequestParams, useWrappedAPIRequest } from '~/src/utils/useWrapAPIRequest'

import { type KenSubmitActionProps } from './types'

type KenFormSubmitAction = Pick<
  RyuActionResolvedProps,
  | 'ref'
  | 'label'
  | 'iconType'
  | 'disabled'
  | 'tooltipContent'
  | 'tooltipPosition'
  | 'onClick'
  | 'buttonProps'
  | 'badge'
>

export type KenFormRenderProps<TFieldValues extends FieldValues> = {
  /**
   *
   * Props for `<form>`.
   *
   * @example
   * <KenForm render={({ formProps }) => <form {...formProps} />} />
   *
   */
  formProps: {
    onSubmit: ReturnType<UseFormHandleSubmit<TFieldValues>>
  }

  /**
   *
   * Returns `RyuActionProps` for the form's submit button.
   *
   * @example
   * <KenForm
   *   render={({ formProps, getSubmitAction }) => (
   *     <RyuDrawerContent
   *       as='form'
   *       asProps={formProps}
   *       actions={[getSubmitAction({ label: 'Submit' })]}
   *     />
   *   )}
   * />
   *
   */
  getSubmitAction: (actionProps: KenSubmitActionProps) => KenFormSubmitAction

  /**
   *
   * Submit handler for programmatic submission.
   *
   * Prefer using `<KenFormSubmitButton>` or `getSubmitAction` where possible.
   *
   */
  onSubmit: ReturnType<UseFormHandleSubmit<TFieldValues>>

  children?: ReactNode
}

export type KenFormImperativeHandle = {
  submit: () => Promise<void>
}

type KenFormProps<TFieldValues extends FieldValues, TContext = any> = {
  imperativeRef?: Ref<KenFormImperativeHandle>
  form: UseFormReturn<TFieldValues, TContext>
  onSubmit: SubmitHandler<TFieldValues>
  onInvalidSubmit?: SubmitErrorHandler<TFieldValues>
  render: (renderProps: KenFormRenderProps<TFieldValues>) => ReactNode
  requestProps?: Pick<
    WrapAPIRequestParams<never>,
    'defaultErrorMessage' | 'showToastOnError' | 'toastSuccessMessage' | 'toastSuccessVariant'
  >
}

export function KenForm<TFieldValues extends FieldValues, TContext = any>({
  imperativeRef,
  form,
  onSubmit: consumerOnSubmit,
  onInvalidSubmit,
  render,
  requestProps,
}: KenFormProps<TFieldValues, TContext>) {
  const {
    formState: { isSubmitting },
    handleSubmit,
  } = form

  const onSubmitWrapped = useWrappedAPIRequest({
    ...requestProps,
    callbackFunction: consumerOnSubmit,
  })

  function onSubmit(event?: BaseSyntheticEvent | undefined) {
    // Prevent submitting outside forms.
    event?.stopPropagation()

    return handleSubmit(onSubmitWrapped, onInvalidSubmit)(event)
  }

  useImperativeHandle(imperativeRef, () => ({
    submit: onSubmit,
  }))

  return (
    <FormProvider {...form}>
      {render({
        formProps: {
          onSubmit,
        },
        getSubmitAction,
        onSubmit,
      })}
    </FormProvider>
  )

  function getSubmitAction({
    label,
    color = 'constructive',
    disabled,
    variant = 'prime',
    iconType,
    tooltipContent,
    tooltipPosition,
    badge,
    ref,
    'data-test-id': dataTestId,
  }: KenSubmitActionProps): RyuActionResolvedProps {
    return {
      ref,
      label,
      iconType: isSubmitting ? 'loading' : iconType,
      disabled: isSubmitting || disabled,
      tooltipContent,
      tooltipPosition,
      badge,
      buttonProps: {
        type: 'submit',
        color,
        variant,
        iconSide: 'right',
        'data-test-id': dataTestId,
      },
    }
  }
}
